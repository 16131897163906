import Cookies from "js-cookie";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import EmailIcon from "../assets/icons/EmailIcon";
import PhoneIcon from "../assets/icons/PhoneIcon";
import { QUERIES } from "../constants/Queries";
import { useAuth } from "../hooks/useAuth";
import { breakpoints } from "../hooks/useBreakPoints";
import { useSnackbar } from "../hooks/useSnackbar";
import { Button } from "../styles/buttons.styled";
import { palette, pxToRem } from "../styles/styleUtils";
import { ISession, ISettings } from "../types/interfaces/authInterTypes";
import { updateSettings } from "../utils/api/Entry";
import { API_COOKIE_NAME } from "../utils/auth";
import { Cell, HeaderCell, Row, StyledTable } from "./Table/Table";

const StyledPrefsTable = styled(StyledTable)`
  position: relative;
  margin-bottom: 50px;
  .reveal-email-settings {
    display: flex;
  }
  svg {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .email-icon {
    svg {
      transform: scaleY(-1);
    }
  }

  .phone-icon {
    display: flex;
    position: relative;
    span {
      position: absolute;
      right: calc(50% - 25px);
      top: 0;
    }
  }
  td,
  th {
    display: flex;
    align-items: center;
    text-align: left;
    min-width: 120px;
    @media (max-width: ${breakpoints.tablet}) {
      min-width: 60px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      align-items: flex-start;
    }
    &:nth-child(2) {
      min-width: 400px;
      @media (max-width: ${breakpoints.tablet}) {
        min-width: 200px;
      }
      @media (max-width: ${breakpoints.mobile}) {
        min-width: 130px;
      }
      display: block;
    }
  }

  tr {
    padding: 20px 0;
    border-bottom: 1px solid #a9a9a9;
  }

  td {
    color: #a9a9a9;
    font-weight: 700;
    font-size: ${pxToRem(16)};
    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${pxToRem(14)};
    }
    &:nth-child(1) {
      font-size: ${pxToRem(20)};
      font-weight: 700;
      @media (max-width: ${breakpoints.tablet}) {
        font-size: ${pxToRem(16)};
      }
    }
    &-nth-child(2) {
      font-size: ${pxToRem(14)};
    }
  }

  .header-row {
    display: flex;
    align-items: center;
    font-size: ${pxToRem(20)};
    th {
      font-weight: 700;
    }
  }

  .disclaimer {
    color: #a9a9a9;
    font-size: ${pxToRem(14)};
    margin-top: 20px;
  }

  .save-prefs {
    margin: 20px auto;
  }

  .error {
    position: absolute;
    top: -10px;
    color: ${palette.liveRed};
  }
`;

const RevealEmailSettings = styled.div`
  display: flex;
  align-items: center;
  .email-settings-title {
    font-size: ${pxToRem(20)};
    font-weight: 700;
    color: #a9a9a9;
    margin-right: 10px;
  }
`;

const PrefsWrapper = styled.div`
  .checkbox-wrapper {
    position: relative;
  }
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -119988px;
    left: -7499.25rem;
  }
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0px;
    left: 0;
    top: 3px;
    top: 0.1875rem;
    width: 19px;
    width: 1.1875rem;
    height: 19px;
    height: 1.1875rem;
    border: 1px dashed #b5b6b8;
    background: #fff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: "✔";
    position: absolute;
    font-size: 0.8125em;
    line-height: 1.84615em;
    color: #b5b6b8;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    top: 0px;
    top: 0;
    left: 5px;
    left: 0.3125rem;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 25px;
    padding-left: 1.5625rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1.25em;
    line-height: 1.46667em;
    height: 23px;
    height: 1.4375rem;
  }
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
  }
`;

const NotificationPrefs = () => {
  const { user } = useAuth();
  const [settings, setSettings] = useState<ISettings | null | undefined>(user?.settings);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const queryClient = useQueryClient();

  const settingsRows = [
    { row_title: "Game Notifications", key: "game", description: "" },
    { row_title: "Empty Roster", key: "roster_empty" },
    {
      row_title: "DNP/CUT",
      key: "roster_dnp",
      description: "Sent when one of your team's players is cut or withdraws",
    },
    {
      row_title: "Round 1 Scoring",
      key: "score_round_1",
      description: "Sent when your team's fantasy points and ranks are updated after round 1 is official",
    },
    {
      row_title: "Round 2 Scoring",
      key: "score_round_2",
      description: "Sent when your team's fantasy points and ranks are updated after round 2 is official",
    },
    {
      row_title: "Round 3 Scoring",
      key: "score_round_3",
      description: "Sent when your team's fantasy points and ranks are updated after round 3 is official",
    },
    {
      row_title: "Round 4 Scoring",
      key: "score_round_4",
      description: "Sent when your team's fantasy points and ranks are updated after round 4 is official",
    },
    { row_title: "Recap", key: "score_final", description: "Sent when all scores are official" },
  ];

  const changeSettings = (key: string, type: "email" | "push") => {
    if (settings) {
      const newSettings = Object.assign({}, settings);
      newSettings[`pgaroster_${key}_notification_${type}`] = !newSettings[`pgaroster_${key}_notification_${type}`];

      setSettings(newSettings);
    }
  };

  const changeEmailSettings = () => {
    if (settings) {
      const newSettings = Object.assign({}, settings);
      newSettings[`pgaroster_private_league_display_email`] = !newSettings[`pgaroster_private_league_display_email`];

      setSettings(newSettings);
    }
  };

  const { mutate } = useMutation(updateSettings, {
    onSuccess: () => {
      addAlert("Settings updated");
      setErrorMessage("");

      queryClient.setQueryData(QUERIES.SESSION, (oldSession: any): ISession => {
        if (oldSession.pgaroster) {
          oldSession.settings = settings;
        }
        return oldSession;
      });
    },
    onError: () => {
      setErrorMessage("Something went wrong. Please try again");
      addAlert("Error while updating settings. Please try again");
    },
  });

  const { addAlert } = useSnackbar();

  const handleUpdateSettings = () => {
    const token = Cookies.get(API_COOKIE_NAME);
    if (settings && user && token) {
      mutate({
        settings: settings,
      });
    }
  };

  return (
    <PrefsWrapper>
      <RevealEmailSettings>
        <div className="email-settings-title">Reveal email in private leagues</div>
        <div className="checkbox-wrapper">
          <input
            id={`reveal-email`}
            type="checkbox"
            checked={settings?.[`pgaroster_private_league_display_email`] ? true : false}
            onClick={() => {
              changeEmailSettings();
            }}
          ></input>
          <label htmlFor={`reveal-email`}>{""}</label>
        </div>
      </RevealEmailSettings>
      <StyledPrefsTable>
        {errorMessage !== "" && <div className="error">{errorMessage}</div>}
        <thead>
          <Row className="header-row">
            <HeaderCell>Alert Type</HeaderCell>
            <HeaderCell>Alert Frequency</HeaderCell>
            <HeaderCell className="phone-icon">
              <PhoneIcon />
              <span>*</span>
            </HeaderCell>
            <HeaderCell className="email-icon">
              <EmailIcon />
            </HeaderCell>
          </Row>
          {settingsRows.map((row, i) => {
            return (
              <Row key={i}>
                <Cell>{row.row_title}</Cell>
                <Cell>{row.description}</Cell>
                <Cell>
                  <div className="checkbox-wrapper">
                    <input
                      id={`${row.key}-alert-push`}
                      type="checkbox"
                      checked={settings?.[`pgaroster_${row.key}_notification_push`]}
                      onClick={() => {
                        changeSettings(row.key, "push");
                      }}
                    ></input>
                    <label htmlFor={`${row.key}-alert-push`}>{""}</label>
                  </div>
                </Cell>
                <Cell>
                  <div className="checkbox-wrapper">
                    <input
                      id={`${row.key}-alert-email`}
                      type="checkbox"
                      checked={settings?.[`pgaroster_${row.key}_notification_email`]}
                      onClick={() => {
                        changeSettings(row.key, "email");
                      }}
                    ></input>
                    <label htmlFor={`${row.key}-alert-email`}>{""}</label>
                  </div>
                </Cell>
              </Row>
            );
          })}
        </thead>
        <Button className={"save-prefs"} onClick={handleUpdateSettings}>
          Save Preferences
        </Button>
      </StyledPrefsTable>
    </PrefsWrapper>
  );
};

export default NotificationPrefs;
