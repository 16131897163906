import Cookies from "js-cookie";
import { API_URL, ENVIRONMENT } from "../settings";
import {
  IGigyaData,
  ISessionPayload,
  ISession,
  IEntry,
  IGigyaCordovaData,
  IGigyaResponse,
} from "../types/interfaces/authInterTypes";

export const API_COOKIE_NAME = "shapi2021";
const apiTokenHeaderSetName = "sh-set-authorization";

export const gigyaOnAfterSubmit = async (data: IGigyaData) => {
  const { response } = data;

  if (response?.profile?.email) {
    const payload: ISessionPayload = {
      email: response.profile.email,
      first_name: response.user?.firstName || null,
      uid_signature: response.UIDSignature,
      signature_timestamp: response.signatureTimestamp,
      uid: response.UID,
    };
    // console.log(payload, "payload");

    const sessionData = await sessionSync(payload);
    return sessionData;
  }
  return false;
};

export const gigyaCordovaAfterSubmit = async (data: IGigyaCordovaData) => {
  const { response, profile, user } = data;
  //@ts-ignore
  let jsonResponse: IGigyaResponse = {};
  try {
    jsonResponse = JSON.parse(response);
  } catch {
    console.warn("No response object found");
  }
  let jsonProfile: { email: string } = { email: "" };
  try {
    jsonProfile = JSON.parse(profile);
  } catch {
    console.warn("No profile object found");
  }
  let jsonUser: any = {};
  try {
    jsonUser = JSON.parse(user);
  } catch {
    console.warn("No user object found");
  }

  if (jsonProfile?.email) {
    const payload: ISessionPayload = {
      uid_signature: jsonResponse.UIDSignature,
      uid: jsonResponse.UID,
      email: jsonProfile?.email || "",
      first_name: jsonUser?.firstName || null,
      signature_timestamp: jsonResponse.signatureTimestamp,
    };
    console.warn("payload: ", payload);
    console.warn("uid: ", jsonResponse.UID);
    console.warn("uid_signature: ", jsonResponse.UIDSignature);
    console.warn("email: ", jsonProfile.email);
    console.warn("timestamp: ", jsonResponse.signatureTimestamp);
    const sessionData = await sessionSync(payload).catch(() => {
      console.error("Session sync error occurred");
    });
    return sessionData;
  }
  return false;
};

export const sessionSync = async (payload: ISessionPayload): Promise<ISession> => {
  // console.log("sessionSync called", token, path);
  return new Promise((resolve, reject) =>
    fetch(`${API_URL}/v1/pgaroster/session/sync.json`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO Better error handling
        reject(new Error(`Error HTTP ${response.status}`));
      } else {
        const resToken = response.headers.get(apiTokenHeaderSetName);
        const data = await response.json();

        if (resToken && ENVIRONMENT.includes("development")) {
          Cookies.set(API_COOKIE_NAME, resToken, { path: "/", expires: 365 });
        }
        resolve(data);
      }
    }),
  );
};

export const getSession = async (userToken: string): Promise<ISession | null> => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/v1/pgaroster/session.json`, {
      method: "get",
      headers: {
        Authorization: userToken,
      },
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (res.status >= 400) {
        if (response?.errors?.session) {
          Cookies.remove(API_COOKIE_NAME);
          reject(new Error(response.errors.session));
        } else {
          reject(new Error("Sorry, something went wrong"));
        }
      } else {
        reject(new Error("Sorry, something went wrong"));
      }
    });
  });
};

export const createEntry = async (nameInput: string) => {
  const token = Cookies.get(API_COOKIE_NAME);
  return new Promise<IEntry>((resolve, reject) =>
    fetch(`${API_URL}/v1/pgaroster/entries.json`, {
      method: "POST",
      headers: {
        Authorization: token || "",
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ name: nameInput }),
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (res.status >= 400) {
        if (response.errors.name) {
          reject(new Error(`Sorry name is taken`));
        }
        reject(new Error(`Sorry, something went wrong ${response.error}`));
      }
    }),
  );
};

export const getTokenServerSide = (req): string | undefined => {
  const cookie = req ? req.headers.cookie : null;
  // console.log(cookie, "server cookie");
  // This cuts the cookie down to the token
  const token = cookie?.split("shapi2021=")[1]?.split(";")[0];
  // console.log(token);
  if (token) {
    return token;
  } else {
    return undefined;
  }
};

export const serverSideAuthCheck = async (req): Promise<ISession | void | null> => {
  const token = getTokenServerSide(req);

  if (token) {
    const userRes = await getSession(token)
      .then((res) => res)
      .catch((err) => {
        console.warn(err, "error on getSession");
      });
    return userRes;
  } else {
    return null;
  }
};
