import { useState } from "react";
import Modal from "../components/Modal/Modal";
import { createContext, SetStateAction, useContext, Dispatch } from "react";

const useModalContext = (): ModalTypes => {
  const [modal, setModal] = useState(false);
  const [canDismiss, setCanDismiss] = useState(true);
  const [isFixed, setIsFixed] = useState(false);
  const [fullScreenMobile, setFullMobile] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element | string | boolean>();

  const handleModal = (content = false) => {
    if (content === false) {
      setModal(false);
      setFullMobile(false);
      setIsFixed(false);
      document.body.classList.remove("modal-open");
    } else {
      setModal(true);
      document.body.classList.add("modal-open");
    }
    if (content) {
      setModalContent(content);
    }
  };

  return {
    modal,
    handleModal,
    modalContent,
    setCanDismiss,
    canDismiss,
    isFixed,
    setIsFixed,
    fullScreenMobile,
    setFullMobile,
  };
};

export default useModalContext;

interface ModalContext {
  undefined;
}
export interface ModalTypes {
  modal: boolean;
  handleModal: (content?: any, forceClose?: boolean) => void;
  modalContent: string | boolean | JSX.Element | undefined;
  canDismiss: boolean;
  setCanDismiss: Dispatch<SetStateAction<boolean>>;
  isFixed: boolean;
  setIsFixed: Dispatch<SetStateAction<boolean>>;
  fullScreenMobile: boolean;
  setFullMobile: Dispatch<SetStateAction<boolean>>;
}

let ModalContext;
const { Provider } = (ModalContext = createContext({} as ModalTypes));

type Props = {
  children?: React.ReactNode;
};

const ModalProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const {
    modal,
    handleModal,
    modalContent,
    canDismiss,
    setCanDismiss,
    isFixed,
    setIsFixed,
    fullScreenMobile,
    setFullMobile,
  } = useModalContext();
  return (
    <Provider
      value={{
        modal,
        handleModal,
        modalContent,
        canDismiss,
        setCanDismiss,
        isFixed,
        setIsFixed,
        fullScreenMobile,
        setFullMobile,
      }}
    >
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };

export const useModal = (): ModalTypes => useContext(ModalContext);
