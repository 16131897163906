import styled from "styled-components";
import { palette } from "../../styles/styleUtils";

const FooterContainer = styled.div`
  margin-bottom: 20px;
  font-weight: 500;
`;

const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${palette.borderGrey};
  border-bottom: 1px solid ${palette.borderGrey};
  font-size: 17px;
  a {
    padding: 20px;
    border-right: 1px solid ${palette.borderGrey};
    text-decoration: auto;
    :first-child {
      border-left: 1px solid ${palette.borderGrey};
    }
    :hover {
      font-weight: bold;
    }
  }
  #ot-sdk-btn.ot-sdk-show-settings {
    border: none;
    color: black;
    padding: 20px;
    border: none;
    border-right: 1px solid ${palette.borderGrey};
    text-decoration: auto;
    font-size: 1em;
    :hover {
      background: white;
      color: black;
      font-weight: bold;
    }
  }
  button {
    padding: 20px;
    border: none;
    border-right: 1px solid ${palette.borderGrey};
    text-decoration: auto;
    :hover {
      font-weight: bold;
    }
  }
`;

const CopyContainer = styled.div`
  text-align: center;
  font-size: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  a {
    text-decoration: auto;
    margin: 20px 0;
    :hover {
      font-weight: bold;
    }
  }
`;

const Footer = (): JSX.Element => {
  return (
    <FooterContainer>
      <FooterLinksContainer>
        <a href="https://www.pgatour.com/company/tos.html" target="_blank" rel="noreferrer noopener">
          Terms Of Service
        </a>
        <a href="https://www.pgatour.com/company/privacy-policy.html" target="_blank" rel="noreferrer noopener">
          Privacy Policy
        </a>
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Choices
        </button>
        <a href="https://www.pgatour.com/tournaments/tickets.html" target="_blank" rel="noreferrer noopener">
          Tournament Tickets
        </a>
        <a href="https://pgatour.wd5.myworkdayjobs.com/PGATOURExternal" target="_blank" rel="noreferrer noopener">
          Careers
        </a>
        <a href="https://www.pgatour.com/company/contact-us.html" target="_blank" rel="noreferrer noopener">
          Contact Us
        </a>
        <a href="https://www.pgatour.com/advertise.html" target="_blank" rel="noreferrer noopener">
          Advertise
        </a>

        {/* <div id="ot-sdk-cookie-policy"></div> */}
      </FooterLinksContainer>
      <CopyContainer>
        <a href="https://www.pgatour.com/do-not-sell.html" target="_blank" rel="noreferrer noopener">
          Do Not Sell My Personal Information
        </a>
        <div>
          © 1995-2023 PGA TOUR, Inc | All Rights Reserved.
          <br />
          PGA TOUR, PGA TOUR Champions, Web.com Tour, and the Swinging Golfer design are registered trademarks.
          <br />
          Web.com is also a registered trademark used here with permission, and used in the Web.com Tour logo with
          permission.
        </div>
      </CopyContainer>
    </FooterContainer>
  );
};

export default Footer;
