import styled from "styled-components";
import { palette } from "../../styles/styleUtils";

const FooterContainer = styled.div`
  margin-bottom: 20px;
  font-weight: 500;
`;

const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${palette.borderGrey};
  border-bottom: 1px solid ${palette.borderGrey};
  font-size: 17px;

  #ot-sdk-btn.ot-sdk-show-settings {
    border: none;
    color: black;
    padding: 20px;
    border: none;
    border-left: 1px solid ${palette.borderGrey};
    border-right: 1px solid ${palette.borderGrey};
    text-decoration: auto;
    font-size: 1em;
    :hover {
      background: white;
      color: black;
      font-weight: bold;
    }
  }
  button {
    padding: 20px;
    border: none;
    border-right: 1px solid ${palette.borderGrey};
    text-decoration: auto;
    :hover {
      font-weight: bold;
    }
  }
`;

const OneTrust = (): JSX.Element => {
  return (
    <FooterContainer>
      <FooterLinksContainer>
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Choices
        </button>
      </FooterLinksContainer>
    </FooterContainer>
  );
};

export default OneTrust;
