import { useQuery, UseQueryResult } from "react-query";
import { ISession } from "../../types/interfaces/authInterTypes";
import Cookies from "js-cookie";
import { API_COOKIE_NAME, getSession } from "../../utils/auth";
import { QUERIES } from "../../constants/Queries";

export const useSessionQuery = (): UseQueryResult<ISession> => {
  const token = Cookies.get(API_COOKIE_NAME);
  // Enabled takes care of the token being undefined
  return useQuery(QUERIES.SESSION, () => getSession(token!), {
    staleTime: 1000 * 30,
    enabled: !!token,
  });
};
