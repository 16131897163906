import { useState } from "react";
import { Button } from "../styles/buttons.styled";
import styled from "styled-components";
import { Row } from "./Table/Table";
import { createEntry } from "../utils/auth";
import { useModal } from "../hooks/useModal";
import { useMutation, useQueryClient } from "react-query";
import { QUERIES } from "../constants/Queries";
import { IEntry, ISession } from "../types/interfaces/authInterTypes";

const EntryModalContainer = styled.div`
  display: flex;
  padding: 40px;
`;
const CreateEntryModal = () => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState("Enter Name for Roster");
  const { handleModal, setCanDismiss } = useModal();
  const { mutate } = useMutation(createEntry, {
    onSuccess: (data: IEntry) => {
      // Optimistically set entry informatio
      queryClient.setQueryData(QUERIES.SESSION, (oldSession: any): ISession => {
        if (oldSession.pgaroster) {
          oldSession.pgaroster.entry = data;
        }
        return oldSession;
      });
      handleModal();
      setCanDismiss(true);
      setMessage("Entry created");
    },
  });
  const queryClient = useQueryClient();

  const handleRosterSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    name &&
      mutate(name, {
        onError: (err) => {
          setMessage(`${err}`);
        },
      });
  };

  const handleNameChange = (value: string) => {
    if (value.length <= 17) {
      setName(value);
    }
  };

  return (
    <EntryModalContainer>
      <div className="label"></div>
      <form onSubmit={(e) => handleRosterSubmit(e)}>
        <label htmlFor="roster-name">{message}</label>
        <Row>
          <input
            id="roster-name"
            type="text"
            placeholder="Roster Name"
            value={name}
            onChange={(e) => handleNameChange(e.target.value)}
          />
          <Button type="submit">Submit</Button>
        </Row>
      </form>
    </EntryModalContainer>
  );
};

export default CreateEntryModal;
