export default function BackArrowIcon(props: any): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.5 22.5" {...props}>
      <title>back arrow icon</title>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M16 .393L5.394 11l1.414 1.414L16 21.607l1.415-1.415L8.223 11l9.192-9.191L16 .393z"
      />
    </svg>
  );
}
