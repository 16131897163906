import { useEffect } from "react";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useAuth } from "../hooks/useAuth";
import { Button } from "../styles/buttons.styled";
import { palette, pxToRem } from "../styles/styleUtils";
import { IEntry, ISession } from "../types/interfaces/authInterTypes";
import { updateEntry } from "../utils/api/Entry";
import { QUERIES } from "../constants/Queries";
import { breakpoints } from "../hooks/useBreakPoints";

const EntryForm = styled.form`
  display: flex;
  align-items: center;
  margin-top: 35px;
  position: relative;
  .text-input {
    min-width: 300px;
    height: 50px;
    border: 1px solid ${palette.borderGrey};
  }

  button {
    margin-left: 10px;
    height: 54px;
    padding: 0 10px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-wrap: wrap;
    button {
      margin: 10px 0 0 0;
    }
    .text-input {
      width: 100%;
    }
  }
`;

const EditEntryContainer = styled.div`
  label {
    position: absolute;
    left: 0;
    top: -25px;
  }
  .edit-title {
    color: ${palette.blue};
    size: ${pxToRem(30)};
    font-weight: bold;
    margin-top: 15px;
  }
`;

const EditEntry = () => {
  const { user } = useAuth();
  const [entryName, setEntryName] = useState<string>("");
  const [message, setMessage] = useState("Enter Name for Roster");

  useEffect(() => {
    setEntryName(user?.pgaroster?.entry?.name || "");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    entryName && mutate({ entryName: entryName, entryId: user?.pgaroster?.entry?.entry_id });
  };

  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateEntry, {
    onSuccess: (data: IEntry) => {
      // Optimistically set entry informatio
      queryClient.setQueryData(QUERIES.SESSION, (oldSession: any): ISession => {
        if (oldSession?.pgaroster?.entry) {
          oldSession.pgaroster.entry = data;
        }
        return oldSession;
      });
      setMessage("Roster name edited");
    },
    onError: (err: Error) => {
      err?.message.includes("name")
        ? setMessage("Sorry that roster name is taken")
        : setMessage("Something went wrong");
    },
  });

  const handleNameChange = (value: string) => {
    if (value.length <= 17) {
      setEntryName(value);
    }
  };

  return (
    <EditEntryContainer>
      <div className="edit-title">Edit Entry Name</div>
      <EntryForm onSubmit={handleSubmit}>
        <label htmlFor="entry-name">{message}</label>

        <input
          id="entry-name"
          type="text"
          className="text-input"
          placeholder={"Entry name"}
          value={entryName}
          onChange={(e) => handleNameChange(e.target.value)}
        />
        <Button type="submit">Save Changes</Button>
      </EntryForm>
    </EditEntryContainer>
  );
};

export default EditEntry;
