import {
  IHistoricalResponse,
  IPlayer,
  IRoundPicks,
  ITournamentHistoricalDetails,
} from "../types/interfaces/RosterTypes";
import { EMPTYPICK, GAMESTATES } from "../constants/Roster";
import { ITournaments } from "../types/interfaces/TournamentTypes";
import { IEntry } from "../types/interfaces/authInterTypes";
import { IRound } from "../types/interfaces/gameTypes";

export const getPlayerFromId = (user, pickId): IPlayer => {
  if (!user || !pickId) {
    return EMPTYPICK;
  }
  return user?.pgaroster?.entry?.current_picks.players.find((player) => player.player_id === pickId) || EMPTYPICK;
};

export const getPlayerFromIdEntry = (entry: IEntry | null | undefined, pickId): IPlayer => {
  if (!entry || !pickId) {
    return EMPTYPICK;
  }
  return entry?.current_picks?.players.find((player) => player.player_id === pickId) || EMPTYPICK;
};

export const getSlotFromPlayerId = (entry: IEntry | undefined, pickId): number => {
  if (entry?.current_picks?.picks[0]) {
    const picks = Object.values(entry?.current_picks?.picks[0]);
    const wrappedIndex = picks.findIndex((pick) => pick === pickId);
    return wrappedIndex;
  }
  return -1;
};

export const findNextAvailableSlot = (currentSlotIndex, currentPicks) => {
  const picks = Object.values(currentPicks);
  const forwardIndex = picks.findIndex((pick, i) => i > currentSlotIndex && !pick);
  if (forwardIndex !== -1) {
    return forwardIndex;
  }
  // if no index "-1" check array from the start
  const wrappedIndex = picks.findIndex((pick, i) => i !== currentSlotIndex && !pick);
  return wrappedIndex;
};

export const findFirstPickId = (picks: IRoundPicks | undefined): number | undefined | null => {
  if (picks) {
    return Object.values(picks).find((pick) => !!pick);
  }
  return null;
};

export const setupHistoricalData = (
  tournaments: ITournaments | undefined | null,
  historicalData: IHistoricalResponse | undefined | null,
) => {
  let currTournament: ITournamentHistoricalDetails | null = null;
  const pastTournaments: ITournamentHistoricalDetails[] = [];
  if (tournaments && historicalData) {
    //fetch name of the current tournament
    if (historicalData.current_tournament_historical) {
      const currData = tournaments?.tournaments?.find(
        (tournament) => tournament.tournament_id === historicalData.current_tournament_historical?.tournament_id,
      );
      if (currData) currTournament = { ...historicalData.current_tournament_historical, name: currData?.name };
    }
    //fetch names of all past tournaments
    if (historicalData?.last_five_tournaments) {
      historicalData.last_five_tournaments.map((pastTournament) => {
        const currData = tournaments?.tournaments?.find(
          (tournament) => tournament.tournament_id === pastTournament?.tournament_id,
        );
        if (currData) pastTournaments.push({ ...pastTournament, name: currData?.name });
      });
    }
  }

  return { currTournament: currTournament, pastTournaments: pastTournaments };
};

const findIndexOfRoundState = (rounds, state) => rounds.findIndex((r) => r.state == state);

export const getLiveRound = (rounds: IRound[]) => {
  if (rounds) {
    let roundIndex = findIndexOfRoundState(rounds, GAMESTATES.IN_PROGRESS);
    // console.log(roundIndex, "in_progress");
    if (roundIndex === -1) {
      // if no rounds in progress set to last completed round
      roundIndex = findLastIndex(rounds, (round) => round.state === GAMESTATES.PLAY_COMPLETE);
      //roundIndex = findIndexOfRoundState([...rounds].reverse(), GAMESTATES.PLAY_COMPLETE);
      // console.log(roundIndex, "play_complete");
      if (roundIndex === -1) {
        roundIndex = findLastIndex(rounds, (round) => round.state === GAMESTATES.OFFICIAL);

        if (roundIndex === -1) {
          roundIndex = 0;
        } else if (roundIndex < rounds.length - 1) {
          roundIndex++;
        }
        // If no complete rounds set to first round
      }
    }
    return roundIndex;
  }
};

export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

export const getPlayersNotTeed = (picks: IPlayer[] | undefined): boolean => {
  let playersNotTeed = false;
  picks?.map((pick) => {
    if (pick?.live === false && pick?.thru !== "F") {
      playersNotTeed = true;
    }
  });
  return playersNotTeed;
};
