import styled from "styled-components";
import { palette, pxToRem } from "../../styles/styleUtils";
import { MaxWidthContainer } from "./Layout";
import BackArrowIcon from "../../assets/icons/BackArrowIcon";
import { FacebookIcon, TwitterIcon } from "../../assets/icons";
import settings from "../../settings";

const TopNavContainer = styled.div`
  display: flex;
  width: 100%;
  height: 37px;
  background-color: ${palette.white};
  justify-content: space-between;
  .left-container {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: 0.2s color ease-in-out;
    font-weight: bold;
    &:hover {
      color: ${palette.black};
    }
    svg {
      width: 18px;
      height: 12px;
    }
    .return-text {
      display: flex;
      align-items: center;
      color: ${palette.textGrey};
      text-transform: uppercase;
      font-size: ${pxToRem(12)};
      .pga-url {
        margin-left: 5px;
        font-size: ${pxToRem(15)};
      }
    }
  }
`;

const TopNav = (): JSX.Element => {
  return (
    <MaxWidthContainer className="mobile-hide">
      <TopNavContainer>
        <a className="left-container" href="https://www.pgatour.com/" rel="noopener noreferrer nofollow">
          <BackArrowIcon />
          <div className="return-text">
            <div>return to</div> <div className="pga-url">PGATOUR.COM</div>
          </div>
        </a>
        <Share />
      </TopNavContainer>
    </MaxWidthContainer>
  );
};

export default TopNav;

const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  & > * {
    margin-left: 7px;
  }
  svg {
    width: 25px;
    height: 25px;
  }
  .share-text {
    text-transform: uppercase;
    color: ${palette.textGrey};
    font-size: ${pxToRem(15)};
  }
`;

export const Share = () => {
  const fbShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${settings.APP_URL}?medium=facebook`,
  )}`;

  const twitterShareURL = `https://twitter.com/share?url=${encodeURIComponent(`${settings.APP_URL}`)}`;
  return (
    <ShareContainer className="share-container">
      <div className="share-text">Share On</div>
      <a href={fbShareURL} target="_blank" rel="noreferrer">
        <FacebookIcon />
      </a>
      <a href={twitterShareURL} target="_blank" rel="noreferrer">
        <TwitterIcon />
      </a>
    </ShareContainer>
  );
};
