import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import SvgCloseIcon from "../../assets/icons/Close";
import { useGameQuery } from "../../hooks/Queries/useGame";
import { pxToRem } from "../../styles/styleUtils";
import { IAlert } from "../../types/interfaces/gameTypes";
import Slider from "react-slick";
import { breakpoints, useBreakpoints } from "../../hooks/useBreakPoints";
const AlertsWrapper = styled(motion.div)`
  background-color: #f66969;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: ${pxToRem(20)};
  position: relative;
  text-align: center;
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  p {
    margin: 0;
    max-width: 100%;
  }
  .close-btn {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      right: 0;
    }
    path {
      fill: #fff;
    }
  }
  .slick-slider {
    width: 100%;
  }
  .slide-wrapper {
    position: relative;
    overflow: hidden;
    @media (max-width: ${breakpoints.rosterMobile}) {
      width: 100%;
      height: 30px;
      margin-right: 30px;
      width: calc(100% - 40px) !important;
      .ticker-wrapper {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .text-sizer {
    position: absolute;
    visibility: hidden;
    p {
      width: max-content;
    }
  }
`;

interface Props {
  duration: number;
  animate: boolean;
  offsetLeft: number;
}

const AnimatingDiv = styled.div<Props>`
  transition: 1.5s linear left;
  @media (max-width: ${breakpoints.rosterMobile}) {
    opacity: 0;
  }
  animation-duration: ${(props) => props.duration}s;
  p {
    width: max-content;
    max-width: initial;
    min-width: 100%;
  }
  @keyframes ticker-animation {
    0% {
      left: 0;
    }

    100% {
      left: -${(props) => props.offsetLeft}px;
    }
  }
  &.current {
    opacity: 1;
  }
  &.animate {
    opacity: 1;
    left: -${(props) => props.offsetLeft}px !important;
    transition: ${(props) => props.duration}s linear left;
    transition-delay: 4000ms;
  }
`;
let currentDuration = 0;
let currentOffset = 0;
const AlertsBanner = () => {
  const { data } = useGameQuery();
  const [currentAlert, setCurrentAlert] = useState<IAlert | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [currentAnimDuration, setCurrentAnimDuration] = useState<number>(0);
  const [currentTickerOffset, setCurrentTickerOffset] = useState<number>(0);
  const [showAlerts, setShowAlerts] = useState<boolean>(true);
  const slider = useRef<Slider>(null);
  const { isRosterMobile } = useBreakpoints();
  useEffect(() => {
    data?.banner_alerts?.length && setCurrentAlert(data.banner_alerts[0]);
  }, [data?.banner_alerts]);
  useEffect(() => {
    let animDuration = 0;
    let offsetLeft = 0;
    if (
      currentAlert?.message?.length &&
      typeof window !== "undefined" &&
      window.innerWidth < currentAlert.message.length * 6.5
    ) {
      animDuration = (currentAlert.message.length * 6.5 - window.innerWidth) / 60;
      offsetLeft = currentAlert.message.length * 6.5 - window.innerWidth + 100;
    }
    let showFillerSlide = false;
    if (data?.banner_alerts?.length === 1 && currentSlide === 1) {
      showFillerSlide = true;
      animDuration = 1;
    }
    if (!showFillerSlide) {
      setCurrentAnimDuration(animDuration);
      setCurrentTickerOffset(offsetLeft);
      currentDuration = animDuration;
      currentOffset = offsetLeft;
    }

    setTimeout(
      () => {
        if (data?.banner_alerts?.length === 1 && currentAnimDuration === 0 && data?.banner_alerts[0]?.message?.length) {
          animDuration = (data?.banner_alerts[0]?.message?.length * 6.5 - window.innerWidth) / 60;
          if (animDuration <= 0) {
            return;
          }
        }
        if (data?.banner_alerts?.length) {
          const idx = currentSlide >= data?.banner_alerts?.length - 1 ? 0 : currentSlide + 1;

          let showFillerSlide = false;
          if (!showFillerSlide) {
            setCurrentAnimDuration(animDuration);
            setCurrentTickerOffset(offsetLeft);
            currentDuration = animDuration;
            currentOffset = offsetLeft;
          }
          if (data?.banner_alerts?.length === 1 && currentSlide === 0) {
            showFillerSlide = true;
          }
          slider?.current?.slickNext();
          setCurrentSlide(showFillerSlide ? 1 : idx);

          if (!showFillerSlide) {
            setCurrentAlert(data?.banner_alerts[idx]);
          } else {
            setCurrentAlert(null);
          }
        }
      },
      !showFillerSlide ? (animDuration + 7) * 1000 : 2000,
    );
  }, [currentAlert]);
  return (
    <AnimatePresence>
      {data?.banner_alerts?.length && showAlerts && (
        <AlertsWrapper
          initial={{ opacity: 0, minHeight: "0px" }}
          animate={{ opacity: 1, minHeight: "50px" }}
          exit={{ opacity: 0, minHeight: "0px" }}
        >
          <Slider
            ref={slider}
            arrows={false}
            dots={false}
            infinite={true}
            adaptiveHeight={true}
            slidesToShow={1}
            fade={true}
            autoplay={isRosterMobile ? false : true}
            touchMove={false}
            autoplaySpeed={10000}
          >
            {data?.banner_alerts.map((alert, i) => {
              return (
                <div key={i} className="slide-wrapper">
                  <AnimatingDiv
                    className={`ticker-wrapper ${currentTickerOffset ? "hasoffset" : ""} ${
                      currentSlide === i ? "current" : ""
                    } ${currentSlide === i && isRosterMobile && currentAnimDuration > 0 ? "animate" : ""}`}
                    duration={currentDuration}
                    offsetLeft={currentOffset}
                    animate={isRosterMobile}
                  >
                    <p>{alert?.message}</p>
                  </AnimatingDiv>
                </div>
              );
            })}
            {data?.banner_alerts && data?.banner_alerts?.length === 1 && isRosterMobile && currentAnimDuration > 0 && (
              <div className="slide-wrapper">
                <AnimatingDiv
                  className={`ticker-wrapper`}
                  duration={currentDuration}
                  offsetLeft={currentOffset}
                  animate={isRosterMobile}
                >
                  <p></p>
                </AnimatingDiv>
              </div>
            )}
          </Slider>
          <button className="close-btn" onClick={() => setShowAlerts(false)}>
            <SvgCloseIcon />
          </button>
        </AlertsWrapper>
      )}
    </AnimatePresence>
  );
};

export default AlertsBanner;
