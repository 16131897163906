import { useState } from "react";
import Image from "next/image";
import styled from "styled-components";

import { MaxWidthContainer } from "./Layout";
import { palette } from "../../styles/styleUtils";
import TopNav from "./TopNav";
import PrimaryNav from "./PrimaryNav";
import { breakpoints, useBreakpoints } from "../../hooks/useBreakPoints";
import UserDisplay from "./UserDisplay";
import MobileMenuButton from "./MobileMenuButton";
import ClientOnly from "../../utils/ClientOnly";
import MobileMenu from "./MobileMenu";
import AlertsBanner from "./AlertsBanner";

const headerHeight = { desktop: "135px", mobile: "44px" };

// https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_pgatour:courses:genericCourseBlur.jpg,f_auto,h_400,q_auto,w_1920/v1/pgatour/courses/r013/752/tourcast/background-r013-752.jpg

const BackgroundWrapper = styled.div`
  background-color: ${palette.blue};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: ${headerHeight.desktop};
  @media (max-width: ${breakpoints.tablet}) {
    height: ${headerHeight.mobile};
  }
`;

const HeaderContainer = styled.div`
  height: ${headerHeight.desktop};
  min-width: 100%;
  display: flex;

  .left-container {
    .fantasy-golf-logo {
      margin-top: 27px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    justify-content: space-between;
    height: ${headerHeight.mobile};
  }
  .left-container {
    display: flex;

    .fantasy-golf-logo {
      margin-top: 10px;
    }
  }
`;

const Header = (): JSX.Element => {
  const { isMobileView } = useBreakpoints();
  const [isOpen, toggleOpen] = useState<boolean>(false);
  const handleToggle = () => {
    toggleOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.remove("modal-open");
    } else {
      document.body.classList.add("modal-open");
    }
  };
  return (
    <header role="banner">
      <ClientOnly>
        <TopNav />
        <BackgroundWrapper>
          <MaxWidthContainer>
            <HeaderContainer>
              <div className="left-container">
                <MobileMenuButton toggle={() => handleToggle()} />
                <MobileMenu isOpen={isOpen} toggleOpen={toggleOpen} />
                <Image
                  src="/images/fantasygolf-uniform-solid.svg"
                  width={isMobileView ? 74 : 231}
                  height={isMobileView ? 26 : 81}
                  alt="pga tour logo"
                  unoptimized={true}
                  className="fantasy-golf-logo"
                />
              </div>
              <UserDisplay mobileOnly />
            </HeaderContainer>
          </MaxWidthContainer>
        </BackgroundWrapper>
        <div className="mobile-hide">
          <PrimaryNav />
        </div>
        <AlertsBanner />
      </ClientOnly>
    </header>
  );
};

export default Header;
