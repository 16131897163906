import { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useQueryClient } from "react-query";
import { QUERIES } from "../constants/Queries";
import { IDragStates, IPlayer, IRosterContext, ISwapPick } from "../types/interfaces/RosterTypes";
import { useBreakpoints } from "./useBreakPoints";
import { EMPTYPICK } from "../constants/Roster";
import { useGameQuery, useMyEntryQuery, useTournamentQuery } from "./Queries/useGame";
import { useUpdatePickMutation } from "./Mutations/useUpdatePickMutation";
import { findFirstPickId } from "../utils/Roster";
import { ITournament } from "../types/interfaces/TournamentTypes";
import { IEntry } from "../types/interfaces/authInterTypes";

const RosterContext = createContext({} as IRosterContext);

interface Props {
  children: React.ReactNode;
}

const RosterProvider = ({ children }: Props) => {
  const { data: gameData } = useGameQuery();
  const { data: entryData } = useMyEntryQuery();
  const { data: tournyData } = useTournamentQuery();
  const queryClient = useQueryClient();
  const [selectedSlot, setSelectedSlot] = useState<null | number>(0);
  const [selectedPlayerId, setSelectedPlayerId] = useState<number | null | undefined>(null);
  const [compareRosterId, setCompareRosterId] = useState<number | null | undefined>(null);
  const [selectedTournamentId, setSelectedTournamentId] = useState<number | null | undefined>(null);
  const [showMobileLeaderboard, setShowMobileLeaderboard] = useState<boolean>(false);
  const [round, setRound] = useState<number>(1);
  const [liveRound, setLiveRound] = useState<number | null>(null);

  const [dragSource, setDragSource] = useState<number | null>(null);
  const [dragDestination, setDragDestination] = useState<number | null | undefined>(undefined);
  const [placeholder, setPlaceholder] = useState<IPlayer | null>(null);
  const [viewingLeague, setViewingLeague] = useState<number | null>(null);
  const [slotDragStates, setSlotDragStates] = useState<IDragStates>({
    slot_1: false,
    slot_2: false,
    slot_3: false,
    slot_4: false,
    slot_5: false,
  });
  const { isRosterMobile } = useBreakpoints();

  // Selected player should start on first picked player
  useEffect(() => {
    const currentPicks = entryData && entryData?.current_picks?.picks?.[round - 1];
    const firstId = !isRosterMobile && currentPicks ? findFirstPickId(currentPicks) : null;
    setSelectedPlayerId(firstId);
  }, [entryData?.current_picks?.players]);

  const showMobileRoster = (isRosterMobile && !showMobileLeaderboard) || !isRosterMobile;

  useEffect(() => {
    // When user selects new round refresh slots
    queryClient.invalidateQueries([QUERIES.SESSION, QUERIES.TOURNAMENT_DETAILS]);
  }, [round]);

  // If tournament changes invalidate queries and refetch
  useEffect(() => {
    queryClient.invalidateQueries([
      QUERIES.PLAYERS,
      QUERIES.SCORECARD,
      QUERIES.SESSION,
      QUERIES.TOURNAMENT,
      QUERIES.VIEWING_ENTRY,
    ]);
  }, [gameData?.current_tournament?.tournament_id, selectedTournamentId]);

  const updateSlotDragState = (slotId: number, dragState: boolean, destination: number | null = null) => {
    if (slotDragStates[`slot_${slotId}`] !== dragState && dragState) {
      setSlotDragStates({ ...slotDragStates, [`slot_${slotId}`]: dragState });
      if (dragState) {
        setDragDestination(destination);
      }
    }
  };

  // Sets placeholder while dragging
  useEffect(() => {
    const tournamentId = selectedTournamentId || gameData?.current_tournament?.tournament_id || 0;

    const isDragging = !Object.values(slotDragStates).every((state) => state === false);
    const entry: IEntry | null | undefined = queryClient.getQueryData([QUERIES.VIEWING_ENTRY, tournamentId]);

    const getPlaceholder = (): IPlayer | null => {
      const picks = entry?.current_picks && entry.current_picks.picks[round - 1];
      const players = entry?.current_picks && entry?.current_picks.players;
      if (dragDestination !== dragSource && isDragging && dragDestination) {
        const placeholderPickId = picks && picks[`slot_${dragDestination}`];
        const placeholder = players && players.find((player) => player.player_id === placeholderPickId);
        if (placeholder) {
          return placeholder;
        } else {
          return EMPTYPICK;
        }
      }

      if (!dragDestination && dragSource) {
        const sourceId = picks && picks[`slot_${dragSource}`];
        const sourcePlaceholder = players && players.find((player) => player.player_id === sourceId);
        return sourcePlaceholder ? sourcePlaceholder : null;
      }

      return null;
    };
    setPlaceholder(getPlaceholder());
  }, [dragSource, dragDestination, slotDragStates]);

  const { mutate, isLoading, isError, isSuccess } = useUpdatePickMutation();

  const onDragEnd = useCallback(
    async ({ source, destination }) => {
      const tournamentData: ITournament | null | undefined = await queryClient.getQueryData([
        QUERIES.TOURNAMENT,
        selectedTournamentId,
      ]);
      setDragSource(null);
      setDragDestination(null);
      if (!destination) {
        return;
      }

      if (destination.index === source.index) {
        return;
      }

      const tournamentId = tournamentData?.tournament_id || gameData?.current_tournament?.tournament_id || 0;
      if (entryData && gameData && tournamentId) {
        const dispatch: ISwapPick = {
          action: "swap",
          round,
          payload: {
            entry_id: entryData.entry_id,
            tournament_id: tournamentId,
            round_seq: round,
            slot_number_from: source.index + 1,
            slot_number_to: destination.index + 1,
          },
        };
        mutate(dispatch);
      }
    },

    [entryData, gameData, round, tournyData, selectedTournamentId],
  );

  return (
    <RosterContext.Provider
      value={{
        showMobileRoster,
        onDragEnd,
        updateSlotDragState,
        setShowMobileLeaderboard,
        showMobileLeaderboard,
        isRosterMobile,
        setDragSource,
        dragSource,
        setDragDestination,
        placeholder,
        compareRosterId,
        setCompareRosterId,
        selectedSlot,
        setSelectedSlot,
        selectedPlayerId,
        setSelectedPlayerId,
        selectedTournamentId,
        setSelectedTournamentId,
        isLoading,
        isError,
        isSuccess,
        round,
        setRound,
        liveRound,
        setLiveRound,
        viewingLeague,
        setViewingLeague,
      }}
    >
      {children}
    </RosterContext.Provider>
  );
};

function useRoster() {
  const context = useContext(RosterContext);
  if (context === undefined) {
    throw new Error("useRoster must be used within a RosterProvider");
  }
  return context;
}
export { RosterProvider, useRoster };

// Queries and Mutations
