import { AnimatePresence } from "framer-motion";
import { Dispatch, SetStateAction } from "react";
import { Button } from "../../styles/buttons.styled";
import { useAuth } from "../../hooks/useAuth";
import { BackDrop, MobileMenuMotion } from "./MobileMenu.styled";
import { PAGES } from "../../constants/Pages";
import { NavLink } from "../../styles/globalStyles";
import Link from "next/link";
import { rightNavArray, rightNavLinkArray } from "./PrimaryNav";
import { Share } from "./TopNav";
import { useRouter } from "next/router";

const variants = {
  closed: {
    x: "-100%",
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
  open: {
    x: "0",
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
};

const backDropVariants = {
  closed: {
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
  open: {
    opacity: 0.8,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
};

interface Props {
  isOpen: boolean;
  toggleOpen: Dispatch<SetStateAction<boolean | undefined>>;
}

const MobileMenu = ({ isOpen, toggleOpen }: Props) => {
  const { login, isAuthenticated } = useAuth();

  const handleSignIn = (action: "register" | "login") => {
    const isReg = action === "register";
    login(undefined, undefined, isReg);
    toggleOpen(!isOpen);
  };
  const router = useRouter();
  return (
    <>
      {/* <SvgCloseIcon className="close" onClick={() => toggleOpen(!isOpen)} /> */}
      <MobileMenuMotion
        className="desktop-hide"
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        initial="closed"
      >
        {!isAuthenticated && (
          <div className="button-row">
            <Button onClick={() => handleSignIn("register")}>Sign Up</Button>
            <Button onClick={() => handleSignIn("login")}>Sign In</Button>
          </div>
        )}

        <div className="hr-divider" />
        {Object.values(PAGES).map((link, i) => {
          if (link === PAGES.LEADERBOARD) {
            return null;
          }
          return (
            <li key={i}>
              <NavLink onClick={() => toggleOpen(!isOpen)}>
                <Link href={link.URL}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  {link.TITLE}
                </Link>
              </NavLink>
            </li>
          );
        })}
        <div className="hr-divider" />
        {rightNavArray.map((link, i) => (
          <li key={i} className="secondary-links">
            <NavLink
              onClick={() => {
                toggleOpen(!isOpen);
                router.push(`/${rightNavLinkArray[i]}`);
              }}
            >
              <Link href={`/${rightNavLinkArray[i]}`}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {link}
              </Link>
            </NavLink>
          </li>
        ))}
        <Share />
      </MobileMenuMotion>
      <AnimatePresence>
        {isOpen && (
          <BackDrop
            className="desktop-hide"
            onClick={() => toggleOpen(!isOpen)}
            animate={isOpen ? "open" : "closed"}
            variants={backDropVariants}
            initial="closed"
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileMenu;
