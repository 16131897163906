import styled from "styled-components";
import { palette, pxToRem } from "../../styles/styleUtils";
import { breakpoints } from "../../hooks/useBreakPoints";
import { useAuth } from "../../hooks/useAuth";
import SvgBlankUser from "../../assets/icons/BlankUser";
import SvgGearIcon from "../../assets/icons/GearIcon";
import { useModal } from "../../hooks/useModal";
import { useEffect } from "react";
import CreateEntryModal from "../CreateEntryModal";
import SettingsModal from "../SettingsModal";

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: ${pxToRem(12)};
  margin-right: 30px;
  min-width: 140px;
  cursor: pointer;
  svg {
    &.blank-user {
      margin-right: 7.5px;
      border-radius: 50%;
    }
    &.gear {
      width: 20px;
      height: 20px;
      margin-left: 5.5px;
      fill: #919191;
    }
  }
  button {
    text-transform: uppercase;
  }
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: row-reverse;
    justify-content: flex-start;
    color: ${palette.white};
    margin-right: 0;
    .blank-user {
      transform: scale(0.75);
    }
    .user-placeholder {
      margin-left: 7.5px;
      width: 32px;
      height: 32px;
    }
  }
`;

const UserInitial = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${pxToRem(24)};
  font-weight: bold;
  border-radius: 50%;
  background: linear-gradient(to bottom, #b5b5b5, #ececec);
  margin-right: 8px;
  @media (max-width: ${breakpoints.tablet}) {
    margin-left: 7.5px;
    width: 32px;
    height: 32px;
    font-size: ${pxToRem(18)};
  }
`;
interface Props {
  mobileOnly?: boolean;
}
const UserDisplay = ({ mobileOnly }: Props) => {
  const { login, isAuthenticated, user, isLoading } = useAuth();
  const { handleModal, setCanDismiss } = useModal();

  // This needs to be here to be inside of modal provider, useRoster can't be inside of modal provider
  useEffect(() => {
    if (user) {
      if (!user?.pgaroster?.entry) {
        // If user is logged in but there is no entry force un-dismissable modal for entry naming
        setCanDismiss(false);
        handleModal(<CreateEntryModal />);
      }
    }
  }, [user]);
  const name = user?.pgaroster?.entry?.name || user?.first_name || "";
  return (
    <UserContainer
      className={mobileOnly ? "desktop-hide" : ""}
      onClick={isAuthenticated ? () => handleModal(<SettingsModal />) : () => login()}
    >
      {user && name !== "" ? (
        <UserInitial>{name[0]}</UserInitial>
      ) : (
        <SvgBlankUser className="blank-user" alt="user image for no privded image" />
      )}

      {isLoading ? (
        "loading..."
      ) : !isAuthenticated ? (
        <button onClick={() => login()}>log in</button>
      ) : (
        <>
          <div>{name !== "" ? `Hello ${name}` : "Hello user"}</div>
          <SvgGearIcon className="gear" />
        </>
      )}
    </UserContainer>
  );
};

export default UserDisplay;
