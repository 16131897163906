function SvgCloseIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>close icon</title>
      <path
        d="M20.192.393l1.415 1.415-9.193 9.191 9.193 9.193-1.415 1.415L11 12.414l-9.191 9.193-1.415-1.415L9.585 11 .393 1.808 1.808.393l9.191 9.192L20.192.393z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCloseIcon;
