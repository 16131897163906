import styled, { css } from "styled-components";
import { palette, pxToRem } from "./styleUtils";

const CoreButtonStyles = css`
  font-size: ${pxToRem(14)};
  line-height: 1em;
  font-family: inherit;
  width: 100%;
  font-weight: 500;
  height: ${pxToRem(32)};
  min-width: ${pxToRem(30)};
  max-width: ${pxToRem(155)};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  :disabled {
    opacity: 0.3;
  }
`;

export const ButtonStyles = css`
  ${CoreButtonStyles}
  border-radius: ${pxToRem(0)};
  border: ${pxToRem(2)} solid ${palette.blue};
  background-color: ${palette.blue};
  color: ${palette.white};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  letter-spacing: 0.96px;
  @media (hover: hover) {
    :hover:not(:active) {
      color: #fff;
      background-color: ${palette.darkBlue};
      border-color: ${palette.darkBlue};
    }
  }
  :active {
    color: #fff;
    background-color: ${palette.darkBlue};
    border-color: ${palette.darkBlue};
  }
  button:disabled,
  button[disabled] {
    opacity: 0.6;
  }
`;

export const Button = styled.button`
  ${ButtonStyles}
`;

export const ButtonClear = styled(Button)`
  background-color: transparent !important;
  border: none !important;

  @media (hover: hover) {
    :hover:not(:active) {
      opacity: 0.8;
    }
  }
  :active {
    opacity: 0.6;
  }
`;
