const SENTRY_DSN = ""; // ! Update this Sentry project key.
const appUrls = {
  staging: `https://fantasygolf-staging.pgatour.com/`,
  development: "https://fantasygolf-dev.pgatour.com/",
  production: "https://fantasygolf.pgatour.com/",
};

const apiUrls = {
  staging: `https://fantasygolf-staging.pgatour.com/api`,
  development: "https://fantasygolf-staging.pgatour.com/api",
  production: "https://fantasygolf.pgatour.com/api",
};

const oneTrustScript = {
  staging: `7367fdb3-bb3b-4f33-916c-1bc845e41866`,
  development: `7367fdb3-bb3b-4f33-916c-1bc845e41866`,
  production: "7367fdb3-bb3b-4f33-916c-1bc845e41866",
};

const LOCAL_DEV_ENV_NAME = "development";

const NODE_ENV = process.env.NODE_ENV ?? LOCAL_DEV_ENV_NAME;
const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV ?? NODE_ENV;

export const ENVIRONMENT = APP_ENV;
export const APP_URL = appUrls[APP_ENV] || appUrls["staging"];
export const API_URL = apiUrls[APP_ENV] || apiUrls["staging"];
export const ONETRUST_SCRIPT = oneTrustScript[APP_ENV] || oneTrustScript["development"];

const GIGYA_API_KEY = APP_ENV.includes("production")
  ? "3_IscKmAoYcuwP8zpTnatC3hXBUm8rPuI-Hg_cZJ-jL-M7LgqCkxmwe-ps1Qy7PoWd"
  : "3_ekqiz5Xd8PSF7MuZ5bXvbz7m-XPHwT5D4FnQ-YCiWJe_z7UecHi-ae7HTUYC6V0h";
const GOOGLE_TAG = "9517547";
const settings = {
  GIGYA_API_KEY,
  GOOGLE_TAG,
  SENTRY_DSN,
  ENVIRONMENT,
  APP_URL,
  API_URL,
  ONETRUST_SCRIPT,
};

export default settings;
