import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { createContext, useContext } from "react";
import { AnimatedSnackbarContainer, PositioningWrapper } from "../components/Loading/Snackbar.styled";
import ClientOnlyPortal from "../utils/ClientOnlyPortal";
import { v4 as uuid4 } from "uuid";

interface IAlert {
  id: number;
  text: string;
  length: number;
}

interface ISnackbar {
  addAlert: (alert: string) => void;
}
export const SnackbarContext = createContext({} as ISnackbar);

const AUTO_DISMISS = 1000;

export function SnackbarProvider({ children }) {
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const activeAlertLength = alerts.length;
  useEffect(() => {
    if (activeAlertLength > 0) {
      const timer = setTimeout(() => setAlerts((alerts) => alerts.splice(1)), AUTO_DISMISS);
      return () => clearTimeout(timer);
    }
  }, [activeAlertLength]);
  const addAlert = (alert: string) =>
    setAlerts((alerts) => [...alerts, { id: uuid4(), text: alert, length: activeAlertLength }]);

  const value = { addAlert };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <ClientOnlyPortal selector="#snackbar-root">
        <motion.div>
          {alerts?.map((alert, i) => {
            return (
              <PositioningWrapper index={alert.length} key={i}>
                <AnimatedSnackbarContainer
                  key={i}
                  initial={{ opacity: 0, x: 500 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 500 }}
                  transition={{ type: "spring", duration: 0.2 }}
                  className={i === 0 ? "first" : ""}
                >
                  <button>{alert.text}</button>
                </AnimatedSnackbarContainer>
              </PositioningWrapper>
            );
          })}
        </motion.div>
      </ClientOnlyPortal>
    </SnackbarContext.Provider>
  );
}
// export { SnackbarContext, SnackbarProvider };

export const useSnackbar = () => useContext(SnackbarContext);
