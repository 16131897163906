import { useMutation, useQueryClient } from "react-query";
import { QUERIES } from "../../constants/Queries";
import { IEntry, ISession } from "../../types/interfaces/authInterTypes";
import { updatePick } from "../../utils/api/Entry";
import { useSnackbar } from "../useSnackbar";
import _cloneDeep from "lodash.clonedeep";
import { useRoster } from "../useRoster";
import { useGameQuery } from "../Queries/useGame";
import { ITournament } from "../../types/interfaces/TournamentTypes";

export const useUpdatePickMutation = () => {
  const queryClient = useQueryClient();
  const { data: gameData } = useGameQuery();
  const { addAlert } = useSnackbar();
  const { setSelectedPlayerId } = useRoster();

  return useMutation(updatePick, {
    onMutate: async (dispatch) => {
      const { round } = dispatch;
      const isRoundOne = dispatch.round === 1;
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(QUERIES.SESSION);
      // Snapshot the previous value
      const previousSession: ISession | undefined = await queryClient.getQueryData(QUERIES.SESSION);
      const previousEntry: IEntry | undefined = await queryClient.getQueryData([
        QUERIES.VIEWING_ENTRY,
        dispatch.payload.tournament_id,
      ]);
      const tournamentId = dispatch.payload.tournament_id;
      const entryData: IEntry | null | undefined = queryClient.getQueryData([
        QUERIES.VIEWING_ENTRY,
        dispatch.payload.tournament_id,
      ]);
      const tournyData: ITournament | null | undefined = queryClient.getQueryData([
        QUERIES.TOURNAMENT,
        dispatch.payload.tournament_id,
      ]);
      // Optimistically update add player
      const newRoundPicks = round &&
        entryData?.current_picks && {
          ...entryData.current_picks.picks[round - 1],
        };
      const newUsage = round &&
        entryData?.usage && {
          ...entryData?.usage,
        };

      if (
        dispatch.action === "save" &&
        isRoundOne &&
        previousSession?.pgaroster?.entry?.current_picks &&
        newRoundPicks &&
        dispatch.pick
      ) {
        newRoundPicks[`slot_${dispatch.payload.slot_number}`] = dispatch.payload.player_id;

        // Add a usage to player if they're not on the bench
        if (newUsage?.[dispatch.payload.player_id] && dispatch.payload.slot_number < 5) {
          newUsage[dispatch.payload.player_id]++;
        } else if (dispatch.payload.slot_number < 5 && newUsage) {
          newUsage[dispatch.payload.player_id] = 1;
        }

        // Add player to array of current picks in order to view in slots map
        previousSession.pgaroster.entry.current_picks.players.push(dispatch.pick);
        if (entryData) {
          entryData?.current_picks?.players?.push(dispatch.pick);
        }
        // console.log(newRoundPicks, "add");
      }
      // Optimistically update drop player
      if (dispatch.action === "drop" && isRoundOne && newRoundPicks) {
        newRoundPicks[`slot_${dispatch.payload.slot_number}`] = null;

        // Remove a usage from a player if they're dropped from main roster
        if (newUsage?.[dispatch.payload.player_id] && dispatch.payload.slot_number < 5) {
          newUsage[dispatch.payload.player_id]--;
        }
        setSelectedPlayerId(null);
        // console.log(newRoundPicks, "drop");
      }

      // Optimistically update swap players
      if (dispatch.action === "swap" && newRoundPicks) {
        const sourceIndex = dispatch.payload.slot_number_from - 1;
        const destinationIndex = dispatch.payload.slot_number_to - 1;
        const slots = Object.values(newRoundPicks);
        const sourceId = slots?.[sourceIndex] || -1;
        const destId = slots?.[destinationIndex] || -1;
        if ((sourceIndex >= 4 || destinationIndex >= 4) && !(sourceIndex >= 4 && destinationIndex >= 4) && newUsage) {
          if (sourceIndex >= 4) {
            if (sourceIndex && sourceId !== -1 && newUsage?.[sourceId]) {
              newUsage[sourceId]++;
            } else if (sourceIndex && sourceIndex !== -1) {
              newUsage[sourceId] = 1;
            }
            if (destinationIndex && destId !== -1 && newUsage?.[destId]) {
              newUsage[destId]--;
            }
          } else if (destinationIndex >= 4) {
            if (sourceIndex && sourceId !== -1 && newUsage?.[sourceId]) {
              newUsage[sourceId]--;
            }
            if (destinationIndex && destId !== -1 && newUsage?.[destId]) {
              newUsage[destId]++;
            } else if (destinationIndex && destId !== -1) {
              newUsage[destId] = 1;
            }
          }
        }
        const newSlots = slots.map((slot, i: number) => {
          if (i === destinationIndex) {
            return slots[sourceIndex];
          } else if (i === sourceIndex) {
            return slots[destinationIndex];
          } else {
            return slot;
          }
        });
        newSlots.forEach((slot, i) => (newRoundPicks[`slot_${i + 1}`] = slot));
      }

      queryClient.setQueryData([QUERIES.VIEWING_ENTRY, tournyData?.tournament_id], () => {
        const tempOldEntry = _cloneDeep(entryData);

        tempOldEntry.current_picks.picks[round - 1] = newRoundPicks;
        tempOldEntry.usage = newUsage;
        // updates all future rounds with the change
        tempOldEntry.current_picks.picks.forEach((_, i) => {
          if (i >= round - 1) {
            tempOldEntry.current_picks.picks[i] = newRoundPicks;
          }
        });
        return tempOldEntry;
      });

      if (tournyData?.tournament_id === gameData?.current_tournament?.tournament_id) {
        queryClient.setQueryData(QUERIES.SESSION, (oldSession: ISession) => {
          const tempOldSession = _cloneDeep(oldSession);
          tempOldSession.pgaroster.entry.usage = newUsage;
          tempOldSession.pgaroster.entry.current_picks.picks[round - 1] = newRoundPicks;
          // updates all future rounds with the change
          tempOldSession.pgaroster.entry.current_picks.picks.forEach((_, i) => {
            if (i >= round - 1) {
              tempOldSession.pgaroster.entry.current_picks.picks[i] = newRoundPicks;
            }
          });

          return tempOldSession;
        });
      }

      // Return a context object with the snapshotted value
      return { previousSession, previousEntry, tournamentId };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, _, context: any) => {
      addAlert("Sorry something went wrong try again");
      queryClient.setQueryData(QUERIES.SESSION, context.previousSession);
      queryClient.setQueryData([QUERIES.VIEWING_ENTRY, context.tournamentId], context.previousEntry);
      queryClient.invalidateQueries(QUERIES.SESSION);
    },
    onSuccess: (data, variables) => {
      if (variables.action === "drop") {
        addAlert("Player Dropped");
      }
      if (variables.action === "save") {
        addAlert(`${variables.pick?.first_name} ${variables.pick?.last_name} Saved`);
      }
      if (variables.action === "swap") {
        addAlert(`Players swapped`);
      }
    },
  });
};
