import styled from "styled-components";
import { palette } from "../../styles/styleUtils";
import { breakpoints } from "../../hooks/useBreakPoints";
import { motion } from "framer-motion";

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: start;

  .backdrop-animated {
    z-index: 100;
  }

  &.fixed-modal {
    position: fixed;
    z-index: 1;
  }
`;

export const AnimatedModalBackdrop = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  background: black;
  backdrop-filter: blur(2px);
  background-size: cover;
`;

export const AnimatedModalContainer = styled(motion.div)`
  z-index: 500;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  max-width: 894px;
  min-width: 300px;
  margin-top: 5%;
  .relative {
    position: relative;

    @media (min-width: ${breakpoints.laptop}) {
      height: 94vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  @media screen and (max-width: 1600px) and (min-width: 900px) and (max-height: 900px) {
    margin-top: 1%;
  }

  .close {
    position: absolute;
    right: -50px;
    top: -3px;
    cursor: pointer;
    z-index: 1;
    fill: ${palette.white};
    stroke-width: 3px;
    height: 35px;
    @media (max-width: ${breakpoints.rosterMobile}) {
      fill: ${palette.black};
      right: 10px;
      top: 10px;
      height: 25px;
    }
    @media (max-width: ${breakpoints.mobile}) {
      position: fixed;
      right: 10px;
      top: 30px;
      z-index: 12;
    }
  }

  @media (max-width: ${breakpoints.rosterMobile}) {
    position: fixed;
    bottom: 0;
    height: 85vh;
    min-width: 100vw;
    overflow: auto;
  }
  @media (max-width: ${breakpoints.mobile}) {
    min-height: 100vh;
    min-width: 100vw;
    top: 0;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  @media (max-width: ${breakpoints.mobile}) {
    .bracket-locked-container {
      height: 100vh;
    }
    // div {
    //   text-align: center;
    // }
  }

  @media (max-width: ${breakpoints.rosterMobile}) {
    &.full-screen-mob {
      height: 100%;
    }
  }
  @media (max-width: ${breakpoints.mobileAds}) {
    margin-bottom: 80px;
  }
`;
