import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { PAGES } from "../../constants/Pages";
import { NavLink } from "../../styles/globalStyles";
import { MaxWidthContainer } from "./Layout";
import { PrimaryNavContainer } from "./PrimaryNav.styled";
import UserDisplay from "./UserDisplay";

export const rightNavArray = ["how to play", "rules", "prizes", "faq"];
export const rightNavLinkArray = ["how-to-play", "rules", "prizes", "faq"];
const PrimaryNav = (): JSX.Element => {
  const { pathname } = useRouter();
  return (
    <MaxWidthContainer>
      <PrimaryNavContainer>
        <nav aria-labelledby="primary navigation" className="left-nav">
          <UserDisplay />
          <ul aria-label="List of pages">
            {Object.values(PAGES).map((link, i) => {
              // This keeps leagues active for /leagues/[id] routes
              if (link === PAGES.LEADERBOARD) {
                return null;
              }
              const isActive = link.URL === PAGES.LEAGUES.URL ? pathname.includes(link.URL) : pathname === link.URL;
              return (
                <li key={i}>
                  <NavLink className={isActive ? "active" : "inactive"}>
                    <Link href={link.URL}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      {link.TITLE}
                    </Link>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
        <nav aria-labelledby="secondary navigation" className="right-nav">
          <ul>
            {rightNavArray.map((link, i) => (
              <li key={i}>
                <div className="secondary-nav-item">
                  <Link href={`/${rightNavLinkArray[i]}`}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {link}
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </nav>
      </PrimaryNavContainer>
    </MaxWidthContainer>
  );
};

export default PrimaryNav;
