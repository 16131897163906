import styled from "styled-components";
import { motion } from "framer-motion";
import { palette } from "../../styles/styleUtils";

export const MobileMenuMotion = styled(motion.div)`
  position: fixed;
  background-color: ${palette.white};
  width: 238px;
  height: 100vh;
  box-sizing: border-box;
  box-shadow: 16px 0 32px -16px #000;
  padding: 32px 18px;
  z-index: 99;
  overflow-x: hidden;
  -moz-outline-radius-bottomright: 0;
  top: 0;
  left: 0;

  .button-row {
    display: flex;
    button:first-of-type {
      margin-right: 10px;
    }
  }

  .hr-divider {
    width: 100%;
    height: 1px;
    width: 198px;
    background-color: ${palette.borderGrey};
    margin-top: 30px;
  }
  a {
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
  }
  ul,
  li {
    list-style-type: none;
    margin: 10% 0 0 0;
    padding: 0;
    display: flex;
    span {
      text-align: start;
    }
  }
  .share-container {
    margin-top: 30%;
  }
  .secondary-links {
    font-size: 12px;
    font-weight: bold;
  }
`;

export const BackDrop = styled(motion.div)`
  opacity: 0.7;
  background-color: ${palette.black};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
`;
