import styled, { css } from "styled-components";

const cellStyles = css`
  display: flex;
  flex-direction: column;
  flex: 0 1 100px;
  &.grow {
    width: auto;
    flex-grow: 1;
  }
`;

const StyledCell = styled.td`
  ${cellStyles}
`;
const StyledHeaderCell = styled.th`
  ${cellStyles}
  font-weight: 500;
  text-align: left;
`;

const StyledRow = styled.tr`
  display: flex;
  flex: 1 0 100%;
  min-width: 100%;
  &.disabled {
    .table-cell {
      opacity: 0.3;
    }
  }
`;

export const StyledTable = styled.table`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

type TableProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

// Table
export function Table({ children, className }: TableProps) {
  return <StyledTable className={`table ${className}`}>{children}</StyledTable>;
}

// Row
export function Row({ children, className }: TableProps) {
  return <StyledRow className={`row ${className}`}>{children}</StyledRow>;
}

export function HeaderCell({ children, className, grow = false }: CellProps) {
  return <StyledHeaderCell className={`header-cell ${className} ${grow && "grow"}`}>{children}</StyledHeaderCell>;
}

type CellProps = {
  children?: React.ReactNode;
  grow?: boolean;
  className?: string;
};

export function Cell({ children, className, grow = false }: CellProps) {
  return <StyledCell className={`table-cell ${className} ${grow && "grow"}`}>{children}</StyledCell>;
}
