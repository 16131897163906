import { IPlayer } from "../types/interfaces/RosterTypes";

export const EMPTYPICK: IPlayer = {
  player_id: -1,
  isEmpty: true,
  first_name: "Select",
  last_name: "Player",
  remote_id: "",
  remote_id_formatted: "",
  live: false,
  started: false,
  position: null,
};

export const UNPICKED: IPlayer = {
  player_id: -1,
  isEmpty: true,
  first_name: "",
  last_name: "",
  remote_id: "",
  remote_id_formatted: "",
  live: false,
  started: false,
  position: null,
};

export const slots = ["slot_1", "slot_2", "slot_3", "slot_4", "slot_5", "slot_6"];

export const GAMESTATES = {
  UNKNOWN: "unknown",
  NOT_STARTED: "not_started",
  GROUPINGS_OFFICIAL: "groupings_official",
  IN_PROGRESS: "in_progress",
  SUSPENDED: "suspended",
  PLAY_COMPLETE: "play_complete",
  OFFICIAL: "official",
};

export const lockedGamesStateArr = [GAMESTATES.OFFICIAL, GAMESTATES.PLAY_COMPLETE];
