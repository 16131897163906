export const QUERIES = {
  GAME: "game",
  LEAGUE_DETAILS: "league",
  LEAGUE_LIST: "league-list",
  LEAGUES: "leagues",
  LEAGUE_LEADERBOARD: "league-leaderboard",
  SELECTED_TOURNAMENT_LEADERBOARD: "selectedTournament-leaderboard",
  PRIVATE_LEAGUE_LEADERBOARD: "private-league-leaderboard",
  LEAGUE_MEMBERS: "league-members",
  SESSION: "session",
  PLAYERS: "players",
  PLAYER_DETAILS: "player-details",
  SCORECARD: "scorecard",
  TOURNAMENTS: "tournaments",
  TOURNAMENT: "tournament",
  SEGMENT: "segment",
  SEASON: "season",
  LEAGUE_HISTORY: "league-history",
  SEGMENT_HISTORY: "segment-history",
  TOURNAMENT_DETAILS: "tournament-details",
  HISTORICAL_DETAILS: "historical-details",
  EDITORIALS: "editorials",
  EXPERT_PICKS: "expert-picks",
  VIEWING_ENTRY: "viewing-entry",
  COMPARING_ENTRY: "comparing-entry",
  CHAMPIONS: "champions",
  COMPARING_SEASON: "comparing-season",
  YEAR: "year",
  ENTRY_HISTORY: "entry-history",
};
