import { useEffect, useRef, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Hydrate } from "react-query/hydration";
import { AuthProvider } from "../hooks/useAuth";
import { ModalProvider } from "../hooks/useModal";
import { RosterProvider } from "../hooks/useRoster";
import { SnackbarProvider } from "../hooks/useSnackbar";
import { AppThemeProvider } from "../hooks/useThemeProvider";
import { useTheme } from "next-themes";

const Providers = ({ children, pageProps }): JSX.Element => {
  const queryClientRef = useRef<any>(null);
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }
  const [mounted, setMounted] = useState(false);
  const { setTheme } = useTheme();
  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    setMounted(true);
    setTheme("light");
    // }
  }, [mounted]);

  return (
    <AppThemeProvider forcedTheme="light" defaultTheme="light" enableSystem={false} disableTransitionOnChange>
      <SnackbarProvider>
        <QueryClientProvider client={queryClientRef.current}>
          <RosterProvider>
            <AuthProvider>
              <ModalProvider>
                <Hydrate state={pageProps.dehydratedState}>
                  {children}
                  <ReactQueryDevtools initialIsOpen={false} />
                </Hydrate>
              </ModalProvider>
            </AuthProvider>
          </RosterProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    </AppThemeProvider>
  );
};

export default Providers;
