import { AnimatePresence } from "framer-motion";
import { AnimatedModalBackdrop, ModalContainer, AnimatedModalContainer, ModalContent } from "./Modal.styled";
import { useModal } from "../../hooks/useModal";
import ClientOnlyPortal from "../../utils/ClientOnlyPortal";
import CloseIcon from "../../assets/icons/ModalClose";

export const Modal = (): JSX.Element | null => {
  const { modalContent, handleModal, modal, canDismiss, isFixed, fullScreenMobile } = useModal();

  return (
    <>
      <ClientOnlyPortal selector="#modal-root">
        <AnimatePresence>
          {modal && (
            <ModalContainer className={`${isFixed ? "fixed-modal" : ""}`}>
              <AnimatedModalContainer
                className={`${fullScreenMobile ? "full-screen-mob" : ""}`}
                key="modal"
                initial={{ opacity: 0, y: 500 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 500 }}
                transition={{ ease: [0.17, 0.67, 0.83, 0.67], duration: 0.16 }}
              >
                <div className="relative">
                  {canDismiss && <CloseIcon className="close" onClick={() => handleModal()} />}
                  <ModalContent className={`${fullScreenMobile ? "full-screen-mob" : ""}`}>{modalContent}</ModalContent>
                </div>
              </AnimatedModalContainer>
              <AnimatedModalBackdrop
                className={`${fullScreenMobile ? "hide-mob" : ""}`}
                onClick={canDismiss ? () => handleModal() : () => null}
                key="modal-backdrop"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.75 }}
                exit={{ opacity: 0 }}
                transition={{ type: "spring", duration: 0.4 }}
              />
            </ModalContainer>
          )}
        </AnimatePresence>
      </ClientOnlyPortal>
    </>
  );
};

export default Modal;
