function SvgModalCloseIcon(props: any) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" {...props}>
      <title>close icon</title>
      <path d="M400,41.667l-41.667,-41.667l-158.333,158.333l-158.333,-158.333l-41.667,41.667l158.333,158.333l-158.333,158.333l41.667,41.667l158.333,-158.333l158.333,158.333l41.667,-41.667l-158.333,-158.333l158.333,-158.333Z" />
    </svg>
  );
}

export default SvgModalCloseIcon;
