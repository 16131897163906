import { createGlobalStyle } from "styled-components";
import { breakpoints } from "../hooks/useBreakPoints";
import { palette } from "./styleUtils";
import styled from "styled-components";

export const GlobalStyles = createGlobalStyle`
html, body {
  margin: 0;
  padding: 0;
  height: auto !important;
  font-size: 100;
  background: white;
;
;
  &.modal-open {
    min-height: 100vh;
    overflow: hidden;
    position: fixed;
  }
}
html {
  font-family: 'Barlow Condensed', sans-serif, Roboto, Arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
  Ubuntu, Cantarell, 'Open Sans';
}
body {
  font-family: inherit;
  color: ${palette.black};
  line-height: 1.5;
  font-size: inherit;
  min-height: 90vh;
  min-width: 100%;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  /* make sure ads and headers never cause x scroll */
  overflow-x: hidden;
  max-width: 100%;
  will-change: scroll-position;
}

header{
  margin-bottom: 23px;
  @media (max-width: ${breakpoints.tablet}) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
  }
}

h1 {
  font-weight: bold;
  font-size: 28px;
  letter-spacing: .88px;
  line-height: 32px;
}
h2 {
  font-weight: bold;
  font-size: 1rem;
}

h1, h2, h3 {
  &.blue {
    color: ${palette.blue};
    text-transform: uppercase;
  }
}

p {

}

a {
    color: ${palette.black};
    &:visited {
      color: ${palette.textGrey};
    }
  }

#root {
  font-family: 'Barlow Condensed', sans-serif;;
}
#root *, #root *:after, #root *:before {
  box-sizing: border-box;
}
#root *:after, #root *:before {
  pointer-events: none;
}
* {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
p, h1, h2, h3, h4, h5, pre, code, .u-selectable {
  user-select: text;
  -webkit-user-select: text;
  -webkit-touch-callout: default;
  white-space: normal;
}
input,
textarea,
select {
  user-select: text;
  -webkit-touch-callout: default;
  border: 1px solid ${palette.borderGrey};
  font-size: 14px;
  padding-left: 11px;
  &::placeholder {
  color: #b4b4b4;
      }
}
input::selection,
textarea::selection {
  background-color: rgba(0, 0, 0, 0.4);
}
body p {
  margin-bottom: 0;
  padding: 0;
  line-height: 1.5em;
  font-size: inherit;
  text-rendering: optimizeLegibility;
}

button, body a {
  cursor: pointer;
  text-transform: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  font: inherit;
  outline: none;
}
button:active, a:active {
  outline: none;
  overflow: hidden;
}
body button, body input, body optgroup, body select, body textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button > *, a > * {
  pointer-events: none;
}
*:disabled {
  pointer-events: none;
  cursor: default;
}
.hidden {
  display: none !important;
}

.mobile-hide {
    @media (max-width: ${breakpoints.tablet}) {
      display: none !important;
    }
  }

.desktop-hide {
  @media (min-width: ${breakpoints.tablet}) {
      display: none !important;
    }
}

/* @font-face {
  font-family: '';
  src: local('') format('woff2'), local('') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: block;
} */

#content{
  @media (max-width: ${breakpoints.tablet}) {
    margin-top: 65px;
    &.has-alerts{
      margin-top: 95px;
    }
  }
  @media (max-width: ${breakpoints.mobileAds}) {
    &.mobile-ads-margin{
      margin-bottom: 90px;
    }
  }
}

`;

export const NavLink = styled.span`
  border-bottom: 2px solid ${palette.white};
  text-transform: uppercase;
  width: 88px;
  margin-right: 5px;
  position: relative;
  text-align: center;
  cursor: pointer;
  &:hover,
  &.active {
    color: ${palette.blue};
    font-weight: bold;
    &:after {
      width: 88px;
    }
  }
  &:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    height: 2px;
    background: ${palette.blue};
  }
`;

export const ErrorText = styled.div`
  color: ${palette.liveRed};
  font-size: 22;
  font-weight: bold;
`;
