export const rootFontSize = 16;
export const pxToRem = (px: number) => `${(px / rootFontSize).toFixed(2)}rem`;

// Primary Colors
const black = "#000";
const blue = "#003a70";
const darkBlue = "#00284b";
const lightBlue = "#0498fe";
const backgroundBlue = "#edf6fe";
const white = "#ffffff";
const borderGrey = "#d5d5d5";
const backgroundGrey = "#eeeeee";
const textGrey = "#515151";
const liveRed = "#B30000";
const correctGreen = "#5fd36d";

export const palette = {
  black,
  white,
  blue,
  darkBlue,
  lightBlue,
  backgroundBlue,
  borderGrey,
  textGrey,
  backgroundGrey,
  liveRed,
  correctGreen,
};

export const maxWidth = "970px";
export const maxPageWidth = "701px";
export const maxDesktopWidth = "1280px";
