import styled from "styled-components";
import { useAuth } from "../hooks/useAuth";
import { breakpoints } from "../hooks/useBreakPoints";
import { useModal } from "../hooks/useModal";
import { Button } from "../styles/buttons.styled";
import { palette, pxToRem } from "../styles/styleUtils";

import EditEntry from "./EditEntry";
import NotificationPrefs from "./NotificationPrefs";
const SettingsModalContainer = styled.div`
  display: flex;
  padding: 40px;
  min-width: 600px;
  flex-direction: column;
  max-height: 75vh;
  overflow-y: auto;
  @media (max-width: ${breakpoints.tablet}) {
    min-width: 330px;
    padding: 20px;
    height: 85vh;
    overflow-y: auto;
    table {
      margin-bottom: 50px;
    }
  }
  .settings {
    &__preferences {
      margin-top: 30px;
    }
    &__account {
      @media (max-width: ${breakpoints.tablet}) {
        & > button {
          margin: 0 auto;
        }
      }
    }
    &__title {
      font-size: ${pxToRem(24)};
      color: ${palette.blue};
      font-weight: 700;
      margin-bottom: 15px;
      @media (max-width: ${breakpoints.tablet}) {
        margin-bottom: 5px;
        font-size: ${pxToRem(18)};
      }
    }
  }
`;

const SettingsModal = () => {
  const { logout } = useAuth();
  const { handleModal } = useModal();
  const handleLogout = () => {
    logout();
    handleModal();
  };

  return (
    <SettingsModalContainer>
      <div className="settings__account">
        <div className="settings__title">Account Settings</div>
        <Button onClick={handleLogout}>Logout</Button>
        <EditEntry />
      </div>
      <div className="settings__preferences">
        <div className="settings__title">Preferences</div>
        <NotificationPrefs />
      </div>
    </SettingsModalContainer>
  );
};

export default SettingsModal;
