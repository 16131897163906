import React from "react";
import styled from "styled-components";
import { ads } from "../constants/Ads";
import { useAdSlot } from "../hooks/useAdSlot";
import { useBreakpoints } from "../hooks/useBreakPoints";

const StyledAd = styled.div`
  z-index: 100;
  @media (max-width: 319px) {
    width: 100%;
    overflow: auto;
  }
  & > div {
    margin: 10px auto !important;
    display: block !important;
  }
  iframe {
    margin: 10px auto !important;
    display: block !important;
  }
`;
interface Props {
  adId: string | undefined;
  pos: string;
  className?: string;
}

function Ad({ adId, pos }: Props): JSX.Element {
  const ID = `${adId}`;
  const ad = ads[ID];
  const { isMobileAds, isTabletAds } = useBreakpoints();
  const platform = isMobileAds ? "pgat.phone" : isTabletAds ? "pgat.tablet" : "pgat.desktop";
  const path = `9517547/${platform}/pgatour/passback/fantasygolf`;

  useAdSlot({
    path,
    size: ad.sizes,
    id: ID,
    pos,
    platform,
  });

  return <StyledAd className={"banner"} id={`div-gpt-ad-${ID}`} />;
}

export default Ad;
