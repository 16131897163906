import { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { maxDesktopWidth } from "../../styles/styleUtils";
import Header from "./Header";
import { GlobalStyles } from "../../styles/globalStyles";
import SkipToContent from "../SkipToContent";
import { breakpoints, useBreakpoints } from "../../hooks/useBreakPoints";
import Ad from "../Ad";
import { adIds } from "../../constants/Ads";
import { useRouter } from "next/dist/client/router";

import Footer from "./Footer";
import { useGameQuery } from "../../hooks/Queries/useGame";
import OneTrust from "./OneTrust";

const RelativeContainer = styled.div`
  position: relative;
  .cookie-wrapper {
    @media (max-width: 395px) {
      & > div > div {
        margin: 5px !important;
      }
      button {
        margin: 0 0 5px 0 !important;
      }
    }
  }
  .CookieConsent {
    z-index: 10000000000000000000;
  }
  @media (min-width: ${breakpoints.tablet}) {
    min-height: 900px;
  }
`;

export const MaxWidthContainer = styled.div<{ leaguesPath?: boolean }>`
  max-width: ${maxDesktopWidth};
  margin: auto;
  @media (max-width: ${maxDesktopWidth}) {
    margin: 0 20px;
  }
  @media (max-width: ${breakpoints.mobileAds}) {
    margin-bottom: 80px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    margin: ${(props) => (props.leaguesPath ? "0" : "0 12px")};
  }
`;

const MobileAdsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  background-color: #ededed;
  width: 100%;
  padding: 4px 0;
  z-index: 100;
`;

interface Props {
  children?: ReactNode;
}
const w = typeof window !== "undefined" ? window : null;

const Layout = ({ children }: Props) => {
  const { isMobileAds, isMobileFloor } = useBreakpoints();
  const { data: gameData } = useGameQuery();
  const { pathname } = useRouter();
  const router = useRouter();
  // const [isCordova, setIsCordova] = useState<boolean>(true);
  useEffect(() => {
    if (w?.isCordova) {
      router.push("/appretire2022");
    }
  }, [w?.isCordova]);

  const leaguesPath = pathname === "/leagues/[id]";

  return (
    <>
      <GlobalStyles />
      <SkipToContent />
      {!w?.isCordova && <Header />}
      <RelativeContainer
        id="content"
        className={`mobile-ads-margin ${gameData?.banner_alerts?.length ? "has-alerts" : ""}`}
      >
        <MaxWidthContainer leaguesPath={leaguesPath} id="snackbar-root">
          {children}
        </MaxWidthContainer>
        {isMobileAds && (
          <MobileAdsWrapper>
            <Ad adId={adIds.MOBILE} pos={"bottom"} />
          </MobileAdsWrapper>
        )}
      </RelativeContainer>
      {!isMobileFloor && !w?.isCordova && <Footer />}
      {isMobileFloor && <OneTrust />}
    </>
  );
};

export default Layout;
