import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import settings from "../settings";

declare global {
  interface Window {
    adsInit: JSON;
  }
}

export type IAdSizes = [970, 250] | [970, 90] | [728, 90] | [300, 50];

interface Props {
  path: string;
  size: number[];
  id: string;
  pos: string;
  platform: string;
}

export function useAdSlot({ path, size, id, pos, platform }: Props): void {
  const router = useRouter();
  // NextJS google adds require transition checks to work correctly
  // TODO limit refresh of slots to naving to and from league pages
  const [isTransitioning, setTransitioning] = useState(false);
  useEffect(() => {
    const setTransitionStarted = () => {
      setTransitioning(true);
    };
    const setTransitionComplete = () => {
      setTransitioning(false);
    };

    router.events.on("routeChangeStart", setTransitionStarted);
    router.events.on("routeChangeComplete", setTransitionComplete);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", setTransitionStarted);
      router.events.off("routeChangeComplete", setTransitionComplete);
    };
  }, []);
  // console.log("transition", isTransitioning);
  if (typeof window !== "undefined") {
    useEffect(() => {
      // console.log(isTransitioning);
      if (!isTransitioning && window.googletag && typeof window !== undefined) {
        if (!window.adsInit[id]) {
          const ad_test = settings.ENVIRONMENT.includes("production") ? "off" : "on";
          window?.googletag?.cmd.push(function () {
            window.googletag.pubads().enableSingleRequest();
            window.googletag.enableServices();

            const slot = window?.googletag
              .defineSlot(path, size, `div-gpt-ad-${id}`)
              .setTargeting("ad_test", [ad_test])
              .setTargeting("s1", ["pgatour"])
              .setTargeting("s2", ["passback"])
              .setTargeting("s3", ["fantasygolf"])
              .setTargeting("pos", [pos])
              .setTargeting("platform", [platform])
              .addService(window?.googletag.pubads());
            window?.googletag.display(`div-gpt-ad-${id}`);

            window.adsInit[id] = slot;
          });
        } else {
          window?.googletag?.cmd.push(function () {
            window?.googletag.pubads().refresh([window.adsInit[id]]);
          });
        }
      }
    }, [id, isTransitioning, window?.googletag]);
  }
}
