import { createContext, useContext, useState } from "react";
import { API_COOKIE_NAME, gigyaCordovaAfterSubmit, gigyaOnAfterSubmit } from "../utils/auth";
import Cookies from "js-cookie";
import { IAuthContext } from "../types/interfaces/authInterTypes";
import { UseMutateFunction, useQueryClient } from "react-query";
import { QUERIES } from "../constants/Queries";
import { useEffect } from "react";
import { useSessionQuery } from "./Queries/useSessionQuery";
import { IJoinLeaguePayload, ILeagueDetails } from "../types/interfaces/leagueTypes";
import { registerDevice } from "../utils/api/Entry";

const AuthContext = createContext({} as IAuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [viewingTournyIds, setViewingTournyIds] = useState<Array<number>>([]);
  const token = Cookies.get(API_COOKIE_NAME);
  const queryClient = useQueryClient();
  const { data: user, isLoading: isLoadingUserSession, isFetching } = useSessionQuery();
  // If query is invalid but still has token and isn't loading clear cache to re-pull session
  if (token && user === null && !isLoadingUserSession && !isFetching) {
    console.warn("No user invalidate and refresh");
    queryClient.invalidateQueries(QUERIES.SESSION);
  }

  useEffect(() => {
    // This is important to ensure this component rerenders when react-query states change
    setIsLoading(isLoadingUserSession);
  }, [user, isLoadingUserSession]);

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  const logout = (): void => {
    if (typeof window !== "undefined") {
      window.gigya.accounts.logout();
    }
    Cookies.remove(API_COOKIE_NAME);
    //queryClient.invalidateQueries();
    queryClient.setQueryData(QUERIES.SESSION, null);

    viewingTournyIds.map((tournyId) => {
      queryClient.setQueryData([QUERIES.VIEWING_ENTRY, tournyId], null);
    });
  };

  const onAfterSubmit = async (
    data,
    joinTokenData?: { group: ILeagueDetails; password: string },
    joinMutate?: UseMutateFunction<ILeagueDetails, Error, IJoinLeaguePayload, unknown>,
  ) => {
    setIsLoading(true);

    const userTemp = window.isCordova ? await gigyaCordovaAfterSubmit(data) : await gigyaOnAfterSubmit(data);
    if (userTemp) {
      queryClient.setQueryData(QUERIES.SESSION, userTemp);
      queryClient.invalidateQueries(QUERIES.SESSION);
      queryClient.invalidateQueries(QUERIES.VIEWING_ENTRY);
      if (window.isCordova) {
        registerDevice(window.deviceUUID).catch(() => {
          console.error("Could not register device");
        });
      }
      if (joinTokenData && userTemp.pgaroster?.entry?.entry_id && joinMutate) {
        // If user has join token join them to league after login
        const payload = { entry_id: userTemp.pgaroster?.entry?.entry_id, password: joinTokenData.password };

        joinMutate({ group: joinTokenData.group, payload });
        // .then(() => {
        //   setJoinedStatus(true);
        //   optimisticJoinGroup(queryClient, joinTokenData.group);
        // })
        // .catch((err) => setJoinedStatus(err.message));
      }
    }
    setIsLoading(false);

    return;
  };

  const login = async (
    joinTokenData?: { group: ILeagueDetails; password: string },
    joinMutate?: UseMutateFunction<ILeagueDetails, Error, IJoinLeaguePayload, unknown>,
    isRegister?: boolean,
  ) => {
    if (typeof window !== "undefined") {
      if (window.isCordova) {
        //@ts-ignore
        gigyaClient.showPlugin(
          "accounts.screenSet",
          {
            screenSet: "Fantasy-RegistrationLogin",
            startScreen: "gigya-long-login-screen",
          },
          {
            onAfterSubmit: async (data) => onAfterSubmit(data, joinTokenData, joinMutate),
            onHide: (event) => {
              setIsLoading(false);
              console.warn("gigya-long-login-screen Screen set has been hidden", event);
            },
          },
        );
      } else {
        window.gigya.accounts.showScreenSet({
          deviceType: "auto",
          screenSet: "Fantasy-RegistrationLogin",
          startScreen: isRegister ? "gigya-login-screen" : "gigya-long-login-screen",
          onDismiss: function (event) {
            setIsLoading(false);
            console.warn("gigya-long-login-screen Screen set has been hidden", event);
          },
          onAfterSubmit: async (data) => {
            onAfterSubmit(data, joinTokenData, joinMutate);
          },
        });
      }
    }
  };

  const addViewingTournyId = (id: number) => {
    !viewingTournyIds.includes(id) && setViewingTournyIds([...viewingTournyIds, id]);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated: !!user,
        logout,
        login,
        addViewingTournyId,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
