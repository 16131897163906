import styled from "styled-components";
import { palette } from "../../styles/styleUtils";

export const PrimaryNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 62px;
  color: ${palette.textGrey};
  a {
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .left-nav {
    display: flex;
    li {
      text-align: center;
      width: 88px;
      margin-right: 5px;
    }
  }
  .right-nav {
    display: flex;
    align-items: center;
    a {
      font-weight: bold;
      font-size: 12px;
      margin-left: 16px;
      border-bottom: 2px solid ${palette.white};
      text-transform: uppercase;
      width: 50px;
      margin-right: 5px;
      position: relative;
      text-align: center;
      &:hover,
      &.active {
        color: ${palette.blue};
        font-weight: bold;
        &:after {
          width: 55px;
        }
      }
      &:after {
        position: absolute;
        transition: 0.3s;
        content: "";
        width: 0;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
        height: 2px;
        background: ${palette.blue};
      }
    }
  }
`;
