import styled from "styled-components";
import { palette } from "../styles/styleUtils";

const SkipToContentLink = styled.a`
  background: ${palette.white};
  height: 30px;
  left: 50%;
  top: -5px;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  &:focus {
    transform: translateY(0%);
  }
`;

const SkipToContent = () => {
  return <SkipToContentLink href="#content">Skip to content</SkipToContentLink>;
};

export default SkipToContent;
