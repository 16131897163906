import { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";

export default function ClientOnlyPortal({ children, selector }) {
  // Selector for creating portals is only available client side
  const ref = useRef<Element | DocumentFragment | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (selector !== undefined) {
      ref.current = document.querySelector(selector);
    }
    setMounted(true);
  }, [selector]);

  if (ref.current === null) {
    return null;
  }

  return mounted ? createPortal(children, ref.current) : null;
}
