import { motion } from "framer-motion";
import styled from "styled-components";
import { palette } from "../../styles/styleUtils";
import { breakpoints } from "../../hooks/useBreakPoints";

export const AnimatedSnackbarContainer = styled(motion.div)`
  display: flex;
  background: ${palette.blue};
  color: white;
  height: 46px;
  min-width: 324px;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
  @media (max-width: ${breakpoints.rosterMobile}) {
    min-width: 250px;
  }
`;

export const PositioningWrapper = styled.span<{ index: number }>`
  position: fixed;
  bottom: ${(props) => 40 + props.index * 48}px;
  right: 0;
  z-index: 10000000;
`;
