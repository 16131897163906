import Cookies from "js-cookie";
import { API_COOKIE_NAME } from "../auth";
import { API_URL } from "../../settings";
import { ISwapPick, IUpdateEntry, IUpdatePick, IUpdateSettings } from "../../types/interfaces/RosterTypes";
import { IEntry } from "../../types/interfaces/authInterTypes";

export const updatePick = async (dispatch: IUpdatePick | ISwapPick): Promise<void | ISwapPick> => {
  const { action } = dispatch;
  const method = action === "swap" ? "PUT" : action === "save" ? "POST" : "DELETE";
  const url = `
    ${API_URL}/v1/pgaroster/picks/${action}.json`;
  const token = Cookies.get(API_COOKIE_NAME);

  // console.log(token, payload);
  // console.log(payload, "payload");
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: method,
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify(dispatch.payload),
    }).then(async (res) => {
      if (res.status === 200) {
        try {
          const swapRes = await res.json();
          resolve(swapRes);
        } catch {
          resolve();
        }
      }
      if (res.status !== 200) {
        reject(new Error("Sorry something went wrong"));
      } else {
        reject(new Error("Sorry something went wrong"));
      }
    }),
  );
};

export const updateEntry = async (entryData: IUpdateEntry): Promise<IEntry> => {
  const url = `${API_URL}/v1/pgaroster/entries/${entryData?.entryId}.json`;
  const token = Cookies.get(API_COOKIE_NAME);
  return new Promise<IEntry>((resolve, reject) =>
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: token || "",
      },
      body: JSON.stringify({ name: entryData.entryName }),
    }).then(async (res) => {
      try {
        const response = await res.json();
        if (res.status === 200) {
          resolve(response);
        }
        if (res.status >= 400) {
          if (response.errors.name) {
            reject(new Error(`Sorry name is taken`));
          }
          reject(new Error(`Sorry, something went wrong`));
        }
      } catch {
        reject(new Error(`Sorry, something went wrong`));
      }
    }),
  );
};

export const updateSettings = async (settingsData: IUpdateSettings) => {
  const url = `${API_URL}/v1/pgaroster/session/settings.json`;
  const token = Cookies.get(API_COOKIE_NAME);

  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: token || "",
      },
      body: JSON.stringify(settingsData),
    }).then(async (res) => {
      if (res.status === 200) {
        resolve(true);
      }
      if (res.status >= 400) {
        reject(new Error(`Sorry, something went wrong`));
      }
    }),
  );
};

function androidOrIOS() {
  if (typeof navigator !== "undefined") {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      return "android";
    }
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
      return "ios";
    }
  }
  return "ios";
}

export const registerDevice = async (deviceId: any) => {
  const url = `${API_URL}/v1/pgaroster/session/devices.json`;
  const token = Cookies.get(API_COOKIE_NAME);
  const platform = androidOrIOS();
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: token || "",
      },
      body: JSON.stringify({ uuid: deviceId, platform }),
    }).then(async (res) => {
      if (res.status === 200) {
        resolve(true);
      }
      if (res.status >= 400) {
        reject(new Error(`Sorry, something went wrong`));
      }
    }),
  );
};
