import { useQuery, UseQueryResult } from "react-query";
import { API_URL } from "../../settings";
import { QUERIES } from "../../constants/Queries";
import { IGame } from "../../types/interfaces/gameTypes";
import { ITournaments, ITournament } from "../../types/interfaces/TournamentTypes";
import { useRoster } from "../useRoster";
import { useAuth } from "../useAuth";
import { IEntry, ISeasonEntry } from "../../types/interfaces/authInterTypes";
import Cookies from "js-cookie";
import { API_COOKIE_NAME } from "../../utils/auth";

export const fetchGames = async (): Promise<IGame> => {
  // TODO Error handling
  const url = `${API_URL}/static-v1/pgaroster/game.json`;
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (response?.errors) {
        reject(new Error(response));
      } else {
        reject();
      }
    }),
  );
};

export const useGameQuery = (): UseQueryResult<IGame> => {
  return useQuery(QUERIES.GAME, fetchGames, {
    staleTime: 1000 * 60 * 2,
  });
};

export const fetchTournament = (id: number): Promise<ITournament> => {
  const url = `
  ${API_URL}/static-v1/pgaroster/tournaments/${id}.json`;
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (response?.errors) {
        reject(new Error(response));
      } else {
        reject();
      }
    }),
  );
};

export const useTournamentQuery = (): UseQueryResult<ITournament> => {
  const { data } = useGameQuery();
  const { selectedTournamentId } = useRoster();
  const id = selectedTournamentId ? selectedTournamentId : data?.current_tournament?.tournament_id;
  // Enabled here prevents id from being null
  return useQuery([QUERIES.TOURNAMENT, id], () => fetchTournament(id!), {
    staleTime: 1000 * 60 * 2,
    enabled: !!id,
  });
};
// ⬇️ if season date (e.g: 2022) is not present use tournaments.json which is for current season.
export const fetchTournaments = (season, current_season): Promise<ITournaments> => {
  const url =
    typeof season != "undefined" && season != current_season
      ? `
  ${API_URL}/static-v1/pgaroster/tournaments/season/${season}.json`
      : `
  ${API_URL}/static-v1/pgaroster/tournaments.json`;
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (response?.errors) {
        reject(new Error(response));
      } else {
        reject();
      }
    }),
  );
};

export const useTournamentsQuery = (season?): UseQueryResult<ITournaments> => {
  const { data: game } = useGameQuery();

  return useQuery([QUERIES.TOURNAMENTS], () => fetchTournaments(season, game?.current_season), {
    staleTime: 1000 * 60 * 15,
  });
};

export const fetchEntry = (
  entryId: number | null,
  tournamentId: number | undefined,
  isUser = false,
): Promise<IEntry> => {
  const url = `
  ${API_URL}/${isUser ? "v1" : "static-v1"}/pgaroster/entries/${entryId || 0}/tournament/${tournamentId}.json`;
  const token = Cookies.get(API_COOKIE_NAME);

  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "get",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: token || "",
      },
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (response?.errors) {
        reject(new Error(response));
      } else {
        reject();
      }
    }),
  );
};

export const fetchSeason = (entryId: number | null): Promise<ISeasonEntry> => {
  const url = `
  ${API_URL}/static-v1/pgaroster/entries/${entryId || 0}/historical.json`;
  const token = Cookies.get(API_COOKIE_NAME);

  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "get",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: token || "",
      },
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (response?.errors) {
        reject(new Error(response));
      } else {
        reject();
      }
    }),
  );
};

export const useMyEntryQuery = (): UseQueryResult<IEntry> => {
  const { data } = useGameQuery();
  const { selectedTournamentId } = useRoster();
  const { user, addViewingTournyId } = useAuth();
  const id = selectedTournamentId ? selectedTournamentId : data?.current_tournament?.tournament_id;
  const entryId = user?.pgaroster?.entry?.entry_id || null;

  //need tourny ids in auth to clear queries on logout
  id && entryId && addViewingTournyId(id);
  return useQuery([QUERIES.VIEWING_ENTRY, id], () => fetchEntry(entryId, id, true), {
    staleTime: 1000 * 60 * 5,
    enabled: !!id && !!entryId,
  });
};

export const useRosterCompareQuery = (entryId: number): UseQueryResult<IEntry> => {
  const { data } = useGameQuery();
  const { selectedTournamentId } = useRoster();

  const id = selectedTournamentId ? selectedTournamentId : data?.current_tournament?.tournament_id;
  return useQuery([QUERIES.COMPARING_ENTRY, entryId, id], () => fetchEntry(entryId, id), {
    staleTime: 1000 * 60 * 5,
    enabled: !!id && !!entryId,
  });
};

export const useSeasonCompareQuery = (entryId: number): UseQueryResult<ISeasonEntry> => {
  return useQuery([QUERIES.COMPARING_SEASON, entryId], () => fetchSeason(entryId), {
    staleTime: 1000 * 60 * 5,
    enabled: !!entryId,
  });
};
