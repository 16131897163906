import type { AppProps /*, AppContext */ } from "next/app";
import App from "next/app";
import Layout from "../components/GlobalLayout/Layout";
import Providers from "../utils/Providers";
import Head from "next/head";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";
import settings from "../settings";
export const hasCordovaHeader = (userAgent: string | null) => {
  if (userAgent) {
    const hasHeader = userAgent.includes("sharplink-cordova");
    return hasHeader;
  }
  return false;
};

declare global {
  interface Window {
    OptanonWrapper: () => void;
    OneTrust: any;
    OptanonActiveGroups: string;
  }
}
function MyApp({ Component, pageProps, userAgent }: AppProps & { userAgent: string | null }): JSX.Element {
  useEffect(() => {
    // if (typeof window !== "undefined") {
    //   window.OptanonWrapper = () => {
    //     window.OneTrust.OnConsentChanged(() => {
    //       Omniture.oneTrustInit();
    //     });
    //   };
    // }
    // if (typeof window !== "undefined") {
    //   window.OptanonWrapper = () => {
    //     Omniture.oneTrustInit();
    //     if (!window.adobeDataLayer[1]) {
    //       Omniture.firePageView("", "");
    //     }
    //   };
    // }
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (hasCordovaHeader(userAgent)) {
        window.isCordova = true;

        document.addEventListener(
          "deviceready",
          () => {
            try {
              //@ts-ignore
              gigyaClient.init(settings.GIGYA_API_KEY, "us1.gigya.com");
              //@ts-ignore
              gigyaClient.addEventListener(gigyaClient.Event.LOGIN, function (data) {
                //@ts-ignore
                console.warn("GIGYA LOGIN EVENT: ", data);
              });
              //@ts-ignore
              gigyaClient.addEventListener(gigyaClient.Event.LOGOUT, function () {
                //@ts-ignore
                console.warn("Gigya logout detected");
              });
            } catch {
              console.error("Something went wrong while initialitizing the cordova gigyaClient");
            }
            try {
              //@ts-ignore
              if (cordova.platformId === "ios") {
                //@ts-ignore
                const idfaPlugin = cordova.plugins.idfa;
                idfaPlugin.getInfo().then((info) => {
                  if (!info.trackingLimited) {
                    return true;
                  } else if (info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {
                    return idfaPlugin.requestPermission().then((result) => {
                      return result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED;
                    });
                  } else {
                    return false;
                  }
                });
              }
            } catch {
              console.error("Something went wrong while initialitizing tracking consent");
            }

            try {
              //@ts-ignore
              window.push = PushNotification.init({
                android: {
                  sound: "true",
                  vibrate: "true",
                },
                ios: {
                  alert: "true",
                  badge: "true",
                  sound: "true",
                },
              });
              //@ts-ignore
              window.push.on("registration", (data) => {
                console.warn("PUSH - registration: ", data);
                window.deviceUUID = data.registrationId;
              });
              //@ts-ignore
              window.push.on("notification", (data) => {
                console.warn("PUSH - notification: ", data);
              });
              //@ts-ignore
              window.push.on("error", (e) => {
                console.warn("PUSH - error: ", e);
              });
            } catch {
              console.error("Something went wrong while initializing push notifications");
            }
          },
          false,
        );
      } else {
        window.isCordova = false;
      }
    }
  }, [userAgent]);

  return (
    <>
      <Head>
        {hasCordovaHeader(userAgent) && (
          <>
            <meta
              name="viewport"
              content="viewport-fit=cover, initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width"
            ></meta>
            <meta
              httpEquiv="Content-Security-Policy"
              content="
            default-src * data: gap: 'unsafe-eval' 'unsafe-inline'; 
            script-src * data: 'unsafe-eval' 'unsafe-inline';
            style-src * 'unsafe-eval' 'unsafe-inline'; 
            font-src *;
            media-src *; 
            img-src * data: content:;"
            />
          </>
        )}
        <meta property="og:url" content={``} key="og-url" />

        <meta property="og:image" content={`${settings.APP_URL}images/fantasy-golf-logo.png`} key="og-image" />
        <meta property="og:image:width" content="1200" key="og-image-width" />
        <meta property="og:image:height" content="628" key="og-image-height" />
        <meta property="og:type" content="website" key="og-type" />

        <meta
          property="og:description"
          content="Think you have the skills to play #PGATOUR fantasy golf?"
          key="og-description"
        />
        <meta property="twitter:card" content="summary_large_image" key="og-twitter-card" />
        <meta property="twitter:site" content="@PGATOUR" key="og-twitter-site" />
        <meta property="og:title" content="PGA TOUR Fantasy Golf" key="og-title" />
        <title>PGA Tour Fantasy Golf</title>
      </Head>
      <Providers pageProps={pageProps}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Providers>
    </>
  );
}

MyApp.getInitialProps = async (appContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  const userAgent = appContext.ctx.req ? appContext.ctx.req.headers["user-agent"] : null;
  return { ...appProps, userAgent };
};

export default MyApp;
