import { useMediaQuery } from "react-responsive";

export const breakpoints = {
  small: "0px,",
  mobileMid: "480px",
  mobile: "640px",
  rosterMobile: "1035px",
  mobileAds: "780px",
  tablet: "885px",
  laptop: "1200px",
  xxlarge: "1440px",
};

export const useBreakpoints = () => {
  let isRosterMobile = false;
  let isMobileSmall = true;
  let isMobileMid;
  let isMobileFloor;
  let isTabletFloor;
  let isTabletMid;
  let isTabletAds;
  let isMobileAds;
  let isMobileView = true;
  let isLaptopFloor;
  let isLaptopCeil;
  let isXHDFloor;
  let isXHDCeil;
  if (typeof window !== "undefined") {
    isRosterMobile = useMediaQuery({
      query: `(max-width: ${breakpoints.rosterMobile})`,
    });
    isMobileSmall = useMediaQuery({ query: "(max-width: 325px)" });
    isMobileMid = useMediaQuery({
      query: `(max-width: ${breakpoints.mobileMid})`,
    });
    isMobileFloor = useMediaQuery({
      query: `(max-width: ${breakpoints.mobile})`,
    });

    isTabletFloor = useMediaQuery({
      query: `(max-width: ${breakpoints.mobile + 1})`,
    });
    isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
    isTabletAds = useMediaQuery({ query: "(max-width: 1020px)" });
    isMobileAds = useMediaQuery({ query: "(max-width: 780px)" });
    isMobileView = useMediaQuery({
      query: `(max-width: ${breakpoints.tablet})`,
    });

    isLaptopFloor = useMediaQuery({
      query: `(max-width: ${breakpoints.tablet + 1})`,
    });
    isLaptopCeil = useMediaQuery({ query: "(max-width: 1440px)" });

    isXHDFloor = useMediaQuery({ query: "(max-width: 1441px)" });
    isXHDCeil = useMediaQuery({ query: "(max-width: 4096px)" });
  }
  return {
    isRosterMobile,
    isMobileSmall,
    isMobileMid,
    isMobileFloor,
    isTabletFloor,
    isTabletMid,
    isTabletAds,
    isMobileAds,
    isMobileView,
    isLaptopFloor,
    isLaptopCeil,
    isXHDFloor,
    isXHDCeil,
  };
};
