export const ads = {
  "tablet-landing-not-logged-in": {
    sizes: [[728, 90]],
  },
  "tablet-landing-logged-in": {
    sizes: [[728, 90]],
  },
  "desktop-landing-not-logged-in": {
    sizes: [
      [970, 90],
      [970, 250],
      [728, 90],
    ],
  },
  "desktop-landing-logged-in": {
    sizes: [
      [970, 90],
      [970, 250],
      [728, 90],
    ],
  },
  mobile: {
    sizes: [[320, 50]],
  },
};

export const adIds = {
  TABLET_LANDING_NOT_LOGGED: "tablet-landing-not-logged-in",
  TABLET_LANDING_LOGGED: "tablet-landing-logged-in",
  DESKTOP_LANDING_NOT_LOGGED: "desktop-landing-not-logged-in",
  DESKTOP_LANDING_LOGGED: "desktop-landing-logged-in",
  MOBILE: "mobile",
};
